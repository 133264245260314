import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../authentication/auth-service.service';
import { config } from 'src/app/config/config';

@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent implements OnInit {
  @Input() public cntDownText: string = '';
  @Input() public cntDwn: number = 0;
  private timer: any = null;
  public startTime: number = 0;

  constructor(
    public activeModal: NgbActiveModal,
    private httpClient: HttpClient,
    private router: Router,
    protected authService: AuthService
  ) { }

  ngOnInit(): void {
    this.stepTimer();
  }

  stepTimer() {
    this.cntDwn = this.startTime - Date.now();
    this.cntDownText = new Date(this.cntDwn).toISOString().substring(14, 19);

    this.timer = setTimeout(() => {
      if (this.cntDwn >= 0) {
        this.stepTimer();
      }
    }, 1000);
  }

  stay() {
    this.httpClient.get<any>(`${config['apiUrl']}/user/fetch`).subscribe(r => { });
    clearTimeout(this.timer);
    this.activeModal.close();
  }

  loggedOut() {
    this.authService.logout().subscribe(r => { });
    this.router.navigate(['/home'])
    this.activeModal.close();
  }
}
