<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Tävling</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <label class="col-3">Giltig till och med</label>
        <div class="col-6">
            <input type='date' [(ngModel)]="invitation.ValidTo">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Kan rösta</label>
        <div class="col-6">
            <input type='checkbox' [(ngModel)]="invitation.CanVote">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Kan delta</label>
        <div class="col-6">
            <input type='checkbox' [(ngModel)]="invitation.CanParticipate">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Tagg</label>
        <div class="col-6">
            <input type='checkbox' [(ngModel)]="invitation.tag">
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="passBack()" type="submit" class="btn btn-primary" [disabled]="!validToSend">
    Ok
  </button>
</div>