import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../config/config';
import { Iuser } from '../model/Iuser';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  constructor(private http: HttpClient) { }

  fetchUsers() {
    return this.http.get<any>(`${config['apiUrl']}/user/fetch`);
  }

  fetchFilteredUsers() {
    return this.http.get<any>(`${config['apiUrl']}/user/fetchFiltered`);
  }

  addUser(user: Iuser) {
    return this.http
      .post<Iuser[]>(
      `${config['apiUrl']}/user/addUser`,
      user
      );

  }

  updateUser(user: Iuser) {
    return this.http
      .post<Iuser[]>(`${config['apiUrl']}/user/updateUser`,
      user
      );
  }
}
