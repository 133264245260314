import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { CompetitionComponent } from './competition/competition/competition.component';
import { InvitationComponent } from './invitation/invitation.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { CompetitionImagesComponent } from './competition-images/competition-images.component';
import { CompetitionVotesComponent } from './competition-votes/competition-votes.component';
import { CompetitionResultComponent } from './competition-result/competition-result.component';
import { CompetitionPresentationComponent } from './competition-presentation/competition-presentation.component';
import { VideoplayerComponent } from './help/videoplayer/videoplayer.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  { path: 'home', component: HomeComponent },
  { path: 'users', component: UsersComponent },
  { path: 'competition', component: CompetitionComponent },
  { path: 'addcompetitionimages', component: CompetitionImagesComponent },
  { path: 'votecompetition', component: CompetitionVotesComponent },
  { path: 'competitionresult', component: CompetitionResultComponent },
  { path: 'invitation/:hash', component: InvitationComponent },
  { path: 'competitionpresentation', component: CompetitionPresentationComponent },
  { path: 'help/:fil', component: VideoplayerComponent },
  { path: 'pwdreset/:hash', component: RecoveryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
