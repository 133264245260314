import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../authentication/auth-service.service';
import { UserServiceService } from './user-service.service';
import { UserModalComponent } from './user-modal/user-modal.component'
import { Iuser } from '../model/Iuser';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public users: Iuser[] = [];

  constructor(private userService: UserServiceService, private modalService: NgbModal, protected authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    if (!this.authService.loggedIn) {
      this.router.navigate(['/home']);
    }
    this.userService.fetchUsers().subscribe(data => {
      this.users = data;
    });
  }

  onAddClick() {
    const modalRef = this.modalService.open(UserModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    let userItem: Iuser = {
      iduser: 0,
      username: '',
      usermail: '',
      globaladmin: 0,
      password: '',
      reset: '',
      resetlimit: ''
    };
    modalRef.componentInstance.user = userItem;

    modalRef.result.then((result) => {
      //this.fronts.push(result);
      this.userService.addUser(result).subscribe(data => {
        this.users = data;
      });
    });
  }

  onEditClick(userItem: Iuser) {
    const modalRef = this.modalService.open(UserModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    let uItem: Iuser = {
      iduser: userItem.iduser,
      username: userItem.username,
      usermail: userItem.usermail,
      globaladmin: userItem.globaladmin,
      password: userItem.password,
      reset: userItem.reset,
      resetlimit: userItem.resetlimit
    };
    modalRef.componentInstance.user = uItem;

    modalRef.result.then((result) => {
      this.userService.updateUser(result).subscribe(data => {
        this.users = data;
      });
    });
  }
}
