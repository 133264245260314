import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../authentication/auth-service.service';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDragDropModule, IOnDropData } from 'ngx-dragdrop';
import { CompetitionVotesService } from './competition-votes.service'
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';
import { ICompetitionImageComment } from '../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../model/ICompetitionImageVote';
import { IVoteContainer } from '../model/IVoteContainer';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { CompareModalComponent } from './compare-modal/compare-modal.component';
import { EntervoteModalComponent } from './entervote-modal/entervote-modal.component';
import { SliderModalComponent } from '../slider-modal/slider-modal.component';
import { NgxDragDropService } from 'ngx-dragdrop'
import { ToastService } from '../service/toast.service';

function simpleHash(str: string) {
    let hash = 0
    for (let i = 0; i < str.length; i++) {
        hash = ((hash << 5) - hash + str.charCodeAt(i)) | 0
    }
    return (hash >>> 0).toString(36)
}

@Component({
    selector: 'app-competition-votes',
    templateUrl: './competition-votes.component.html',
    styleUrls: ['./competition-votes.component.scss']
})
export class CompetitionVotesComponent implements OnInit {
    public competitions: Icompetition[] = [];
    voteMapper: number[] = [7, 5, 4, 3, 2, 1];
    timer: any = null;
    maxScrollY = 0;
    maxScrollX = 0;
    edgeSize = 10;
    isInLeftEdge = false;
    isInRightEdge = false;
    isInTopEdge = false;
    isInBottomEdge = false;
    viewportX = 0;
    viewportY = 0;
    viewportWidth = 0;
    viewportHeight = 0;
    edgeTop = 0;
    edgeBottom = 0;
    edgeLeft = 0;
    edgeRight = 0;

    constructor(private zone: NgZone, private competitionService: CompetitionVotesService, private modalService: NgbModal, protected authService: AuthService, private router: Router, private toaster: ToastService, dragService: NgxDragDropService) {
        if (!this.authService.loggedIn) {
            this.router.navigate(['/home']);
        }
        //dragService.onDragStart
    }

    onMouseDown(event: any) {
        console.log("Mousedown", 'Client: ' + event.clientX + ', ' + event.clientY + ' Layer: ' + event.layerX + ', ' + event.layerY + ' Offset: ' + event.offsetX + ', ' + event.offsetY + ' Page: ' + event.pageX + ', ' + event.pageY + ' Screen: ' + event.screenX + ', ' + event.screenY + ' Pos: ' + event.x + ', ' + event.y);
    }

    onMouseUp(event: any) {
        console.log("Mouseup", 'Client: ' + event.clientX + ', ' + event.clientY + ' Layer: ' + event.layerX + ', ' + event.layerY + ' Offset: ' + event.offsetX + ', ' + event.offsetY + ' Page: ' + event.pageX + ', ' + event.pageY + ' Screen: ' + event.screenX + ', ' + event.screenY + ' Pos: ' + event.x + ', ' + event.y);
    }

    onMouseLeave(event: any) {
        console.log("Mouseleave", 'Client: ' + event.clientX + ', ' + event.clientY + ' Layer: ' + event.layerX + ', ' + event.layerY + ' Offset: ' + event.offsetX + ', ' + event.offsetY + ' Page: ' + event.pageX + ', ' + event.pageY + ' Screen: ' + event.screenX + ', ' + event.screenY + ' Pos: ' + event.x + ', ' + event.y);
    }

    onMouseMove(event: any) {
        console.log("Mousemove", 'Client: ' + event.clientX + ', ' + event.clientY + ' Layer: ' + event.layerX + ', ' + event.layerY + ' Offset: ' + event.offsetX + ', ' + event.offsetY + ' Page: ' + event.pageX + ', ' + event.pageY + ' Screen: ' + event.screenX + ', ' + event.screenY + ' Pos: ' + event.x + ', ' + event.y);
    }

    onDraggableMoved(event: any) {

        console.log("draggable moved", JSON.stringify(event));

        // NOTE: Much of the information here, with regard to document dimensions,
        // viewport dimensions, and window scrolling is derived from JavaScript.info.
        // I am consuming it here primarily as NOTE TO SELF.
        // --
        // Read More: https://javascript.info/size-and-scroll-window
        // --
        // CAUTION: The viewport and document dimensions can all be CACHED and then
        // recalculated on window-resize events (for the most part). I am keeping it
        // all here in the mousemove event handler to remove as many of the moving
        // parts as possible and keep the demo as simple as possible.

        // Get the viewport-relative coordinates of the mousemove event.
        this.viewportX = event.clientX;
        this.viewportY = event.clientY;

        // Get the viewport dimensions.
        this.viewportWidth = document.documentElement.clientWidth;
        this.viewportHeight = document.documentElement.clientHeight;

        // Next, we need to determine if the mouse is within the "edge" of the
        // viewport, which may require scrolling the window. To do this, we need to
        // calculate the boundaries of the edge in the viewport (these coordinates
        // are relative to the viewport grid system).
        this.edgeTop = this.edgeSize;
        this.edgeLeft = this.edgeSize;
        this.edgeBottom = (this.viewportHeight - this.edgeSize);
        this.edgeRight = (this.viewportWidth - this.edgeSize);

        this.isInLeftEdge = (this.viewportX < this.edgeLeft);
        this.isInRightEdge = (this.viewportX > this.edgeRight);
        this.isInTopEdge = (this.viewportY < this.edgeTop);
        this.isInBottomEdge = (this.viewportY > this.edgeBottom);

        // If the mouse is not in the viewport edge, there's no need to calculate
        // anything else.
        if (!(this.isInLeftEdge || this.isInRightEdge || this.isInTopEdge || this.isInBottomEdge)) {

            clearTimeout(this.timer);
            return;

        }

        // If we made it this far, the user's mouse is located within the edge of the
        // viewport. As such, we need to check to see if scrolling needs to be done.

        // Get the document dimensions.
        // --
        // NOTE: The various property reads here are for cross-browser compatibility
        // as outlined in the JavaScript.info site (link provided above).
        var documentWidth = Math.max(
            document.body.scrollWidth,
            document.body.offsetWidth,
            document.body.clientWidth,
            document.documentElement.scrollWidth,
            document.documentElement.offsetWidth,
            document.documentElement.clientWidth
        );
        var documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.body.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );

        // Calculate the maximum scroll offset in each direction. Since you can only
        // scroll the overflow portion of the document, the maximum represents the
        // length of the document that is NOT in the viewport.
        this.maxScrollX = (documentWidth - this.viewportWidth);
        this.maxScrollY = (documentHeight - this.viewportHeight);

        // As we examine the mousemove event, we want to adjust the window scroll in
        // immediate response to the event; but, we also want to continue adjusting
        // the window scroll if the user rests their mouse in the edge boundary. To
        // do this, we'll invoke the adjustment logic immediately. Then, we'll setup
        // a timer that continues to invoke the adjustment logic while the window can
        // still be scrolled in a particular direction.
        // --
        // NOTE: There are probably better ways to handle the ongoing animation
        // check. But, the point of this demo is really about the math logic, not so
        // much about the interval logic.
        let self = this;
        (function checkForWindowScroll() {

            clearTimeout(self.timer);

            if (self.adjustWindowScroll()) {

                self.timer = setTimeout(checkForWindowScroll, 30);

            }

        })();
    }

    adjustWindowScroll() {

        // Get the current scroll position of the document.
        var currentScrollX = window.pageXOffset;
        var currentScrollY = window.pageYOffset;

        // Determine if the window can be scrolled in any particular direction.
        var canScrollUp = (currentScrollY > 0);
        var canScrollDown = (currentScrollY < this.maxScrollY);
        var canScrollLeft = (currentScrollX > 0);
        var canScrollRight = (currentScrollX < this.maxScrollX);

        // Since we can potentially scroll in two directions at the same time,
        // let's keep track of the next scroll, starting with the current scroll.
        // Each of these values can then be adjusted independently in the logic
        // below.
        var nextScrollX = currentScrollX;
        var nextScrollY = currentScrollY;

        // As we examine the mouse position within the edge, we want to make the
        // incremental scroll changes more "intense" the closer that the user
        // gets the viewport edge. As such, we'll calculate the percentage that
        // the user has made it "through the edge" when calculating the delta.
        // Then, that use that percentage to back-off from the "max" step value.
        var maxStep = 50;

        // Should we scroll left?
        if (this.isInLeftEdge && canScrollLeft) {

            var intensity = ((this.edgeLeft - this.viewportX) / this.edgeSize);

            nextScrollX = (nextScrollX - (maxStep * intensity));

            // Should we scroll right?
        } else if (this.isInRightEdge && canScrollRight) {

            var intensity = ((this.viewportX - this.edgeRight) / this.edgeSize);

            nextScrollX = (nextScrollX + (maxStep * intensity));

        }

        // Should we scroll up?
        if (this.isInTopEdge && canScrollUp) {

            var intensity = ((this.edgeTop - this.viewportY) / this.edgeSize);

            nextScrollY = (nextScrollY - (maxStep * intensity));

            // Should we scroll down?
        } else if (this.isInBottomEdge && canScrollDown) {

            var intensity = ((this.viewportY - this.edgeBottom) / this.edgeSize);

            nextScrollY = (nextScrollY + (maxStep * intensity));

        }

        // Sanitize invalid maximums. An invalid scroll offset won't break the
        // subsequent .scrollTo() call; however, it will make it harder to
        // determine if the .scrollTo() method should have been called in the
        // first place.
        nextScrollX = Math.max(0, Math.min(this.maxScrollX, nextScrollX));
        nextScrollY = Math.max(0, Math.min(this.maxScrollY, nextScrollY));

        if (
            (nextScrollX !== currentScrollX) ||
            (nextScrollY !== currentScrollY)
        ) {

            window.scrollTo(nextScrollX, nextScrollY);
            return (true);

        } else {

            return (false);

        }

    }


    async ngOnInit(): Promise<void> {
        const t: any = await this.authService.getCurrentUser$().toPromise();
        const u = JSON.parse(t.aud);

        this.authService.loggedIn.subscribe((l: boolean) => {
            if (!l) {
                this.router.navigate(['/home']);
            }
        });

        this.competitionService.fetchCompetitions().subscribe((data: Icompetition[] | any) => {
            data.map((c: Icompetition) => {
                c.IdString = c.Id.toString();
                this.competitionService.fetchSubCompetitions(c.Id).subscribe((s: Isubcompetition[] | any) => {
                    c.subCompetitions = s;
                    s.map((m: Isubcompetition) => {
                        this.competitionService.fetchCompetitionImages(m).subscribe((img: ICompetitionImage[]) => {
                            m.images = img;
                            m.votes = []
                            let timeoutId: any = null;
                            img.filter(f => {
                                return u.iduser !== f.UserId;
                            }).map((i: ICompetitionImage) => {
                                i.VoteTitle = simpleHash(i.Title);
                                const vc: IVoteContainer = {
                                    img: i,
                                    comment: null,
                                    vote: null
                                }

                                this.competitionService.fetchCompetitionImageComment(i.CompetitionId, i.SubId, i.ImageId, u.iduser).subscribe(co => {
                                    vc.comment = co;
                                });
                                this.competitionService.fetchCompetitionImageVote(i.CompetitionId, i.SubId, i.ImageId, u.iduser).subscribe(co => {
                                    if (co) {
                                        vc.vote = co;
                                    } else {
                                        vc.vote = {
                                            CompetitionId: c.Id,
                                            SubId: m.Id,
                                            ImageId: i.ImageId,
                                            UserId: u.iduser,
                                            SortOrder: -1,
                                            VoteValue: -1
                                        };

                                    }
                                    if (timeoutId) {
                                        clearTimeout(timeoutId);
                                    }
                                    timeoutId = setTimeout(() => {
                                        if (m.votes) {
                                            m.votes.map(v => {
                                                let r = 0;
                                                do {
                                                    r = Math.floor(Math.random() * (m.votes ? m.votes.length : 0));
                                                }
                                                while (m.votes ?.find(f => (f.vote ? f.vote.SortOrder : -1) === r) && v.vote ?.SortOrder === -1);
                                                if (v.vote && v.vote.SortOrder === -1) {
                                                    v.vote.SortOrder = r;
                                                }
                                            });
                                            m.votes.sort((a, b) => {
                                                if ((a.vote ? a.vote.SortOrder : 0) < (b.vote ? b.vote.SortOrder : 0)) {
                                                    return -1
                                                } else {
                                                    return 1
                                                }
                                            });
                                        }
                                    }, 1000);
                                });
                                if (i.UserId !== u.iduser || c.participantCanAddImage === 0 || u.globaladmin) {
                                    m.votes ?.push(vc);
                                }
                            });

                        });
                    });
                })
            });
            this.competitions = data;
        });
    }

    onDrop(event: IOnDropData, vote: IVoteContainer) {
        const comp: Icompetition | undefined = this.competitions ?.find(f => f.Id === vote.img.CompetitionId);
        const sub = comp ?.subCompetitions ?.find((f: Isubcompetition) => f.Id === vote.img.SubId);
        if (sub) {
            const votes = sub.votes;
            if (votes) {
                const dragVote: IVoteContainer | undefined = votes.find(f => f.img.ImageId === event.dragData.img.ImageId);
                if (dragVote && dragVote.vote && vote.vote) {
                    if (dragVote.vote.SortOrder === vote.vote.SortOrder) {
                        //return;
                    }

                    const startOrder = dragVote.vote.SortOrder;
                    const stopOrder = vote.vote.SortOrder;
                    dragVote.vote.SortOrder = vote.vote.SortOrder;

                    this.competitionService.updateCompetitionVote(dragVote.vote).subscribe((d: any) => {

                    });
                    if (startOrder < stopOrder) {
                        for (let i = stopOrder; i > startOrder; i--) {
                            const changeVote = votes[i].vote;
                            if (changeVote) {
                                changeVote.SortOrder = i - 1;
                                this.competitionService.updateCompetitionVote(changeVote).subscribe((d: any) => {
                                });
                            }
                        }
                    } else {
                        for (let i = stopOrder; i < startOrder; i++) {
                            const changeVote = votes[i].vote;
                            if (changeVote) {
                                changeVote.SortOrder = i + 1;
                                this.competitionService.updateCompetitionVote(changeVote).subscribe((d: any) => {
                                });
                            }
                        }
                    }

                    votes.sort((a, b) => {
                        if ((a.vote ? a.vote.SortOrder : 0) < (b.vote ? b.vote.SortOrder : 0)) {
                            return -1
                        } else {
                            return 1
                        }
                    });
                }
            }
        }
        //console.log(event);
    }

    async onAddClick(vote: IVoteContainer) {
        const modalRef = this.modalService.open(CommentModalComponent, {
            backdrop: 'static',
            size: 'xl',
        });

        if (vote.comment) {
            modalRef.componentInstance.formattedText = vote.comment.Comment;
            modalRef.componentInstance.IsAnonymous = vote.comment.IsAnonymous;
        }

        const t: any = await this.authService.getCurrentUser$().toPromise();
        const u = JSON.parse(t.aud);

        modalRef.result.then((result: any) => {
            const c: ICompetitionImageComment = {
                CompetitionId: vote.img.CompetitionId,
                SubId: vote.img.SubId,
                ImageId: vote.img.ImageId,
                UserId: u.iduser,
                Comment: result.formattedText,
                IsAnonymous: result.IsAnonymous ? 1 : 0
            }

            this.competitionService.saveCompetitionImageComment(c).subscribe(v => {
                vote.comment = v;
                this.toaster.show('success', 'Kommentar', 'Kommentaren sparad');
            });;
        });
    }

    onZoomClick(sub: Isubcompetition, v: IVoteContainer) {
        const modalRef = this.modalService.open(CompareModalComponent, {
            backdrop: 'static',
            size: 'xl',
        });
        modalRef.componentInstance.images = sub.votes;
        modalRef.componentInstance.idx = v.vote ?.SortOrder;
        modalRef.result.then((result: any) => {
        });
    }

    showSlider(sub: Isubcompetition, v: IVoteContainer) {
        const modalRef = this.modalService.open(SliderModalComponent, {
            backdrop: 'static',
            size: 'xl',
        });
        modalRef.componentInstance.images = sub.votes;
        setTimeout(() => {
            const focus = modalRef.componentInstance.carousel.pauseOnFocus;
            modalRef.componentInstance.carousel.pauseOnFocus = false;
            modalRef.componentInstance.carousel.select('lightbox-' + v.vote ?.SortOrder.toString());
            modalRef.componentInstance.carousel.pauseOnFocus = focus;
        }, 10);
        modalRef.result.then((result: any) => {
        });
    }

    onVoteClick(comp: Icompetition, sub: Isubcompetition) {
        const modalRef = this.modalService.open(EntervoteModalComponent, {
            backdrop: 'static',
            size: 'xl',
        });
        const votes: IVoteContainer[] = [];
        let count = 0;
        sub.votes ?.forEach(v => {
            console.log(this.voteMapper[count]);
            votes.push({
                img: {
                    CompetitionId: v.img.CompetitionId,
                    SubId: v.img.SubId,
                    ImageId: v.img.ImageId,
                    UserId: v.img.UserId,
                    orgPath: v.img.orgPath,
                    imgUrl: v.img.imgUrl,
                    srcSet: v.img.srcSet,
                    iso: v.img.iso,
                    focal: v.img.focal,
                    aperture: v.img.aperture,
                    shutter: v.img.shutter,
                    Title: v.img.Title,
                    Camera: v.img.Camera,
                    Model: v.img.Model,
                    Comment: v.img.Comment
                },
                comment: {
                    CompetitionId: v.comment ? v.comment.CompetitionId : 0,
                    SubId: v.comment ? v.comment.SubId : 0,
                    ImageId: v.comment ? v.comment.ImageId : 0,
                    UserId: v.comment ? v.comment.UserId : 0,
                    Comment: v.comment ? v.comment.Comment : '',
                    IsAnonymous: v.comment ? v.comment.IsAnonymous : 0
                },
                vote: {
                    CompetitionId: v.vote ? v.vote.CompetitionId : 0,
                    SubId: v.vote ? v.vote.SubId : 0,
                    ImageId: v.vote ? v.vote.ImageId : 0,
                    UserId: v.vote ? v.vote.UserId : 0,
                    SortOrder: v.vote ? v.vote.SortOrder : 0,
                    VoteValue: v.vote ? Math.max(this.voteMapper[count] ? this.voteMapper[count] : 0, 0) : 0,
                }
            });
            count++;
        });
        modalRef.componentInstance.images = votes;
        modalRef.result.then((result: IVoteContainer[]) => {
            let t = 6;
            result.forEach(v => {
                if (v.vote) {
                    this.competitionService.updateCompetitionVote(v.vote).subscribe((d: any) => { });
                    setTimeout(() => {
                        this.toaster.show('success', 'Röstning', 'Röstningen lyckades ' + v.img.Title, t * 1000);
                    }, (t - 6) * 500);
                    t++;
                }
            });
        });
    }
}
