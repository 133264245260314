<div class="container">
    <div class="card" style="margin-bottom: 15px">
        <div class="card-header">
            <div class="row" style="margin-left: '15px';margin-right:'15px'">

                <div class="col-5 col-sm-5"><strong>Tävlingsnamn</strong>
                </div>
                <div class="col-7 col-md-4 col-lg-2"><strong>Sista dag för inlämnande</strong>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="card" *ngFor="let competition of competitions">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5 col-sm-5">{{competition.CompetitionName}}</div>
                        <div class="col-7 col-md-4 col-lg-2">{{competition.EndoParticipation | date:'yyyy-MM-dd'}}</div>
                    </div>
                </div>
                <div class="card-body">
                    <tabset>
                        <tab *ngFor="let sub of competition.subCompetitions let i = index" heading="{{sub.SubName}}">
                            <div class="card" style="margin-bottom: 15px">
                                <div class="card-header">
                                    <div class="row" style="margin-left: '15px';margin-right:'15px'">
                                        <div class="col-10" [innerHtml]="sub.SubCompTheme"></div>
                                        <div class="col-2">
                                            <Button (click)="onAddClick(sub)"><fa-icon [icon]="['fas', 'plus']"></fa-icon></Button>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let img of sub.images">
                                            <div class="card">
                                                <div class="card-body">
                                                    <img src="https://foto.erikslada.se{{img.imgUrl}}" srcset="{{img.srcSet}}" width="100%">
                                                </div>
                                                <div class="card-footer">
                                                    <div class="row">
                                                        <div class="col-12" style="text-align: center;"><strong>{{img.Title}}</strong></div>
                                                    </div>
                                                    <div class="row" *ngIf="img.iso">
                                                        <div class="col-6">ISO</div>
                                                        <div class="col-6">{{img.iso}}</div>
                                                    </div>
                                                    <div class="row" *ngIf="img.shutter">
                                                        <div class="col-6">Slutare</div>
                                                        <div class="col-6">{{img.shutter}}</div>
                                                    </div>
                                                    <div class="row" *ngIf="img.aperture">
                                                        <div class="col-6">Bländare</div>
                                                        <div class="col-6">{{img.aperture}}</div>
                                                    </div>
                                                    <div class="row" *ngIf="img.focal">
                                                        <div class="col-6">Brännvidd</div>
                                                        <div class="col-6">{{img.focal}}</div>
                                                    </div>
                                                </div>
                                                                                                    <div class="row">
                                                        <div class="col-12">
                                                            <Button 
                                                                (click)="onRemoveClick(img)" 
                                                                class="btn btn-primary position-relative"
                                                                style="margin-left:15px;"
                                                                ngbPopover="Ta bort bild"
                                                                triggers="mouseenter:mouseleave"
                                                                popoverTitle="Ta bort bild"
                                                            >
                                                                <fa-icon [icon]="['fas', 'minus']"></fa-icon>
                                                            </Button>
                                                            <Button 
                                                                (click)="onEditClick(img)" 
                                                                class="btn btn-primary position-relative"
                                                                style="margin-left:15px;"
                                                                ngbPopover="Redigera"
                                                                triggers="mouseenter:mouseleave"
                                                                popoverTitle="Redigera titel"
                                                            >
                                                                <fa-icon [icon]="['fas', 'pen']"></fa-icon>
                                                            </Button>
                                                        </div>
                                                    </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </div>
</div>
