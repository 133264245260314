<tabset>
    <tab *ngFor="let sub of subCompetitions let i = index" heading="{{sub.theme.SubName}}">
        <div class="card" style="margin-bottom: 15px">
            <div class="card-header">
                <div class="row" style="margin-left: '15px';margin-right:'15px'">
                    <div class="col-10" [innerHtml]="sub.theme.SubCompTheme"></div>
                    <div class="col-2">                                                            
                        <Button
                            (click)="onResultClick(sub)" class="btn btn-primary position-relative"
                        >
                            <fa-icon [icon]="['fas', 'project-diagram']"></fa-icon>
                        </Button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <app-images-result [images]=sub.images [competition]=competition [user]=user></app-images-result>
            </div>
        </div>
    </tab>
</tabset>
