import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpInterceptor
} from '@angular/common/http';
import { AuthService } from './authentication/auth-service.service';
import { EventBusService } from './shared/event-bus.service';
import { EventData } from './shared/event-data';
import { Observable } from 'rxjs';
import { catchError, tap } from "rxjs/operators";

@Injectable()
export class LoginInterceptor implements HttpInterceptor {
  private timeout: any = undefined;

  constructor(private authService: AuthService, private eventBusService: EventBusService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const u = this.authService.getCurrentUser$();
    const t = this.authService.getToken();
    const req = request.clone({
      setHeaders: { Authorization: `Token:${t}` }
    });
    return next.handle(req).pipe(
      tap((event) => {
        if (event.type === 0) {
          return;
        }
        //console.log(event);
        //console.log(`req.url ==> ${req.url}`);
        if (event instanceof HttpResponse) {
          if (event.headers.has('Token')) {
            const Token = event.headers.get('Token');
            this.authService.handleLogin(Token);
            if (this.timeout) {
              clearTimeout(this.timeout);
            }

            console.log('Logout: ' + this.authService.logoutTime + ' ' + Token);
            this.timeout = setTimeout(
              () =>
                this.eventBusService.emit(
                  new EventData('logout', this.authService.logoutTime)
                ),
              this.authService.logoutTime - Date.now() - 120000
            );

            //console.log(Token);
          }
        }
        //console.log('REQUEST HEADERS: ', event.headers);
      }),

    );
  }
}
