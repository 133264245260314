import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icompetition } from '../../model/Icompetition';
import { Isubcompetition } from '../../model/Isubcompetition';
import { ICompetitionImage } from '../../model/ICompetitionImage';
import { ICompetitionImageComment } from '../../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../../model/ICompetitionImageVote';
import { IVoteContainer } from '../../model/IVoteContainer';
import { UserServiceService } from '../../users/user-service.service';
import { ResultModalComponent } from '../result-modal/result-modal.component';
import { Iuser } from '../../model/Iuser'
import { IComp, ISub, IVote, IVoter } from '../competition-result-types';

@Component({
  selector: 'app-sub-competition',
  templateUrl: './sub-competition.component.html',
  styleUrls: ['./sub-competition.component.scss']
})
export class SubCompetitionComponent implements OnInit {
  @Input() competition: IComp | null = null;
  @Input() user: Iuser | undefined = undefined;
  @Input() subCompetitions: ISub[] = [];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  onResultClick(theme: ISub) {
    const modalRef = this.modalService.open(ResultModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });

    modalRef.componentInstance.theme = theme;
    modalRef.componentInstance.competition = this.competition;

    modalRef.result.then(async (result: any) => {
    });
  }
}
