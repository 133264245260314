import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Toast } from "../service/toast.interface";
@Component({
  selector: "app-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"]
})
export class ToastComponent {
  @Input() toast?: Toast;
  @Input() i: number = 0;

  @Output() remove = new EventEmitter<number>();
}
