import { Component, OnInit } from '@angular/core';
import { Iuser } from '../model/Iuser';
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';
import { ICompetitionImageComment } from '../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../model/ICompetitionImageVote';
import { IVoteContainer } from '../model/IVoteContainer';
import { CompetitionPresentationService } from './competition-presentation.service';

class IVote {
    img: ICompetitionImage;
    ImageId: number;
    Title: string;
    SumVote: number;
    Comments: ICompetitionImageComment[];
    Position: number;
    highestVote: number;
    VoteCounts: IVoteCount[];

    constructor(i: ICompetitionImage) {
        this.img = i;
        this.ImageId = i.ImageId;
        this.Title = i.Title;
        this.SumVote = 0;
        this.Comments = [];
        this.Position = -1;
        this.highestVote = -1;
        this.VoteCounts = [];
    }

    AddVote(v: number) {
        if (v <= 0) {
            return;
        }

        this.SumVote += v;

        if (this.highestVote < v) {
            this.highestVote = v;
        }

        const vc = this.VoteCounts.find(f => f.vote === v);

        if (vc) {
            vc.count++;
        } else {
            this.VoteCounts.push({ vote: v, count: 1 });
        }
    }

    getCount(i: number) {
        const vc = this.VoteCounts.find((f: IVoteCount) => f.vote === i);

        if (!vc) {
            return 0;
        }
        return vc.count;
    }

    compareCount(i: number, other: IVote): number {
        if (i <= 0) {
            return 0;
        }

        if (this.getCount(i) === other.getCount(i)) {
            return this.compareCount(i - 1, other);
        }
        else {
            return other.getCount(i) - this.getCount(i);
        }
    }

    compare(other: IVote): number {
        if (this.SumVote === other.SumVote) {
            if (this.highestVote === other.highestVote) {
                return this.compareCount(this.highestVote, other);
            }
            else {
                return other.highestVote - this.highestVote;
            }
        }

        return other.SumVote - this.SumVote;
    }
}

class IVoteCount {
    vote: number;
    count: number;
    constructor(v: number) {
        this.vote = v;
        this.count = 1;
    }
}

interface IComp {
    comp: Icompetition;
    themes: ISub[];
}

interface ISub {
    theme: Isubcompetition;
    images: IVote[];
}

@Component({
    selector: 'app-competition-presentation',
    templateUrl: './competition-presentation.component.html',
    styleUrls: ['./competition-presentation.component.scss']
})
export class CompetitionPresentationComponent implements OnInit {
    public competitions: IComp[] = [];
    private users: Iuser[] = [];
    //private voters: IVoter[] = [];

    constructor(private service: CompetitionPresentationService) { }

    ngOnInit(): void {
        this.service.fetchCompetitions().subscribe((cs: Icompetition[]) => {
            this.competitions = [];
            cs.map((c: Icompetition) => {
                const mc: IComp = {
                    comp: c,
                    themes: []
                };
                this.competitions.push(mc);
                this.service.fetchSubCompetitions(c.Id).subscribe(ss => {
                    ss.map((s: Isubcompetition) => {
                        const ms: ISub = {
                            theme: s,
                            images: [],
                        };
                        mc.themes ?.push(ms);

                        this.service.fetchCompetitionImages(ms.theme).subscribe((is: ICompetitionImage[]) => {
                            is.map((i: ICompetitionImage) => {
                                const v: IVote = new IVote(i);

                                this.service.fetchCompetitionImageComments(s.CompetitionId, s.Id, i.ImageId).subscribe((co: ICompetitionImageComment[]) => {
                                    v.Comments = co;
                                })

                                this.service.fetchCompetitionImageVotes(s.CompetitionId, s.Id).subscribe((vo: ICompetitionImageVote[]) => {
                                    vo.map((vv: ICompetitionImageVote) => {
                                        if (v.ImageId === vv.ImageId) {
                                            v.AddVote(vv.VoteValue);
                                        }
                                    })
                                })

                                ms.images ?.push(v);
                            })
                        })
                        setTimeout(() => {
                            ms.images.sort((a: IVote, b: IVote) => {
                                return a.compare(b);
                            })

                            let i = 1;
                            let last: IVote | undefined = undefined;
                            ms.images.map((m: IVote) => {
                                if (!last || last.compare(m) != 0) {
                                    m.Position = i;
                                } else {
                                    m.Position = last ?.Position;
                                }
                                last = m;
                                i++;
                            })
                        }, 5000);
                    })
                })
            })
        });
    }

}
