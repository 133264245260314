import { Iuser } from '../model/Iuser';
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';
import { ICompetitionImageComment } from '../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../model/ICompetitionImageVote';

export class IVote {
    img: ICompetitionImage;
    ImageId: number;
    Title: string;
    SumVote: number;
    Comments: ICompetitionImageComment[];
    Position: number;
    highestVote: number;
    VoteCounts: IVoteCount[];

    constructor(i: ICompetitionImage) {
        this.img = i;
        this.ImageId = i.ImageId;
        this.Title = i.Title;
        this.SumVote = 0;
        this.Comments = [];
        this.Position = -1;
        this.highestVote = -1;
        this.VoteCounts = [];
    }

    AddVote(v: number) {
        if (v <= 0) {
            return;
        }

        this.SumVote += v;

        if (this.highestVote < v) {
            this.highestVote = v;
        }

        const vc = this.VoteCounts.find(f => f.vote === v);

        if (vc) {
            vc.count++;
        } else {
            this.VoteCounts.push({ vote: v, count: 1 });
        }
    }

    getCount(i: number) {
        const vc = this.VoteCounts.find((f: IVoteCount) => f.vote === i);

        if (!vc) {
            return 0;
        }
        return vc.count;
    }

    compareCount(i: number, other: IVote): number {
        if (i <= 0) {
            return 0;
        }

        if (this.getCount(i) === other.getCount(i)) {
            return this.compareCount(i - 1, other);
        }
        else {
            return other.getCount(i) - this.getCount(i);
        }
    }

    compare(other: IVote): number {
        if (this.SumVote === other.SumVote) {
            if (this.highestVote === other.highestVote) {
                return this.compareCount(this.highestVote, other);
            }
            else {
                return other.highestVote - this.highestVote;
            }
        }

        return other.SumVote - this.SumVote;
    }
}

export class IVoteCount {
    vote: number;
    count: number;
    constructor(v: number) {
        this.vote = v;
        this.count = 1;
    }
}

export interface IVoter {
    user: Iuser;
    votes: ICompetitionImageVote[];
}

export interface IComp {
    comp: Icompetition;
    themes: ISub[];
}

export interface ISub {
    theme: Isubcompetition;
    images: IVote[];
    voters: IVoter[];
}

