<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Automatisk utloggning</h4>
</div>
<div class="modal-body">
  <div *ngIf="cntDwn > 0">
    <div>Du kommer att loggas ut om {{ cntDownText }}</div>
  </div>
  <div *ngIf="cntDwn < 0">
    <div>Du är utloggad</div>
  </div>
</div>
<div class="modal-footer">
  <div *ngIf="cntDwn > 0">
    <div><Button bsStyle="primary" (click)="stay()">Stanna kvar</Button></div>
  </div>
  <div *ngIf="cntDwn < 0">
    <div><Button bsStyle="primary" (click)="loggedOut()">Ok</Button></div>
  </div>
</div>
