<div class="container">
    <div class="card" style="margin-bottom: 15px">
        <div class="card-header">
            <div class="row">
                <div class="col-6">Namn</div>
                <div class="col-5">Epost</div>
                <div class="col-1">
                    <Button (click)="onAddClick()"><fa-icon [icon]="['fas', 'plus']"></fa-icon></Button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row" *ngFor="let user of users">
                <div class="col-6">{{user.username}}</div>
                <div class="col-5">{{user.usermail}}</div>
                <div class="col-1">
                    <Button (click)="onEditClick(user)"><fa-icon [icon]="['fas', 'pen']"></fa-icon></Button>
                </div>
            </div>
        </div>
    </div>
</div>