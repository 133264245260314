            <div class="row" *ngIf="recovered">
                <div class="col-12">Lösenordet återställt</div>
            </div>
            <div class="row form-group" *ngIf="!loggedIn && !recovered">
                <div class="col-12" *ngIf="!recovered">
                  <strong>Uppdatera ditt lösenord.</strong>
                </div>
                <div class="col-3"><label>Lösenord</label></div><div class="col-8"><input type="password" class="form-control" [(ngModel)]="pwd" (keypress)="onChange()"></div>
                <div class="col-3"><label>Upprepa Lösenord</label></div><div class="col-8"><input type="password" class="form-control" [(ngModel)]="pwd2" (keypress)="onChange()"></div>
                <div class="alert alert-danger col-12" role="alert" *ngIf="signupError">
                    {{signupError}}
                </div>
                <div class="col-3"><Button class="btn btn-primary" [disabled]="!valid" (click)="onRegister()">Uppdatera</Button></div>
            </div>
