import { Component, inject, OnDestroy, TemplateRef, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth-service.service';
import { LoginRequest } from '../model/login-request';
import { User } from '../model/user';
import { ToastService } from '../service/toast.service';

@Component({
  selector: 'app-app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.sass']
})
export class AppHeaderComponent implements OnInit {
  loginForm: FormGroup = new FormGroup({});
  loggedIn: boolean = false;
  @Input() email?: string;
  @Input() password?: string;
  public user?: string;

  constructor(protected authService: AuthService, private router: Router, private toaster: ToastService) {
    this.authService.loggedInChange.subscribe((value) => {
      this.loggedIn = value;
      const t: any = this.authService.user;
      this.user = t ?.username;
    });
  }

  ngOnInit(): void {
    this.authService.loggedIn.subscribe((l: any) => {
      console.log('App-header: ' + l);
      const t: any = this.authService.user;
      this.user = t ?.username;
    });
  }

  login() {
    const loginRequest: LoginRequest = new LoginRequest();
    loginRequest.email = this.email;
    loginRequest.password = this.password;
    this.authService.login(loginRequest).subscribe((response) => {
      //      this.loggedIn = !!response.confirmed;
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
      if (response) {
        this.toaster.show("success", "Inloggning", "Inloggningen lyckades.");
      }
      else {
        this.toaster.show("error", "Inloggning", "Ogiltigt epost eller lösenord.");
      }
    });
  }

  recover() {
    this.authService
      .sendRecover(this.email ? this.email : '')
      .subscribe((response) => {
        this.toaster.show("success", "Lösenord", "En epost med länk till återställande av lösenordet är skickat till dig.", 20000);
        //this.router.navigate(['recovery']);
      });
  }

  logout() {
    this.authService.logout().subscribe((response) => {
      //this.loggedIn = false;
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      }); console.log(response);
      this.toaster.show("success", "Utloggning", "Utloggningen lyckades.");
    });
  }
}
