<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Användare</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <label class="col-3">Namn</label>
        <div class="col-6">
            <input [(ngModel)]="user.username">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Epost</label>
        <div class="col-6">
            <input [(ngModel)]="user.usermail">
        </div>
    </div>
    <div class="row" *ngIf="user.globaladmin">
        <label class="col-3">Global admin</label>
        <div class="col-6">
            <input type="checkbox" [(ngModel)]="user.globaladmin">
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="passBack()" type="submit" class="btn btn-primary" [disabled]="!validToSend">
    Ok
  </button>
</div>