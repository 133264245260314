import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as THREE from 'three';
import { Icompetition } from '../../model/Icompetition';
import { Isubcompetition } from '../../model/Isubcompetition';
import { ICompetitionImage } from '../../model/ICompetitionImage';
import { ICompetitionImageComment } from '../../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../../model/ICompetitionImageVote';
import { bezier, Vector } from './bezier';
import { IComp, ISub } from '../competition-result-types';

declare var Slider: any;

interface IVote {
    img: any;
    startT: number | null;
    startPos: THREE.Vector3;
    targetPos: THREE.Vector3;
    startRotation: THREE.Vector3;
    targetRotation: THREE.Vector3;
    bez: THREE.Vector3[];
    ImageId: number;
    Title: string;
    SumVote: number;
    pos?: number;
}

@Component({
    selector: 'app-result-modal',
    templateUrl: './result-modal.component.html',
    styleUrls: ['./result-modal.component.scss'],
})
export class ResultModalComponent implements OnInit {
    @Input() competition: IComp | undefined;
    @Input() theme: ISub | undefined;
    private canvas: any;
    private ctx: any;
    private votes: IVote[] = [];
    private u: number = 0;
    private i: number = 0;
    public disabled: boolean = false;
    public volume: number = 50;
    @ViewChild('mainCanvas') canvasElem: ElementRef | any;

    constructor(public activeModal: NgbActiveModal) { }

    ngOnInit(): void {
    }

    createThreeJsBox() {
        const self = this;
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, this.canvas.width / this.canvas.height, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ canvas: this.canvas });
        renderer.setSize(700, 500);
        //document.body.appendChild(renderer.domElement);

        ///var mesh = new THREE.Mesh(geometry, mat);




        const animate = function (t: number) {
            requestAnimationFrame(animate);

            self.votes.map(m => {
                if (m.img) {
                    if (m.startT === null) {
                        m.startT = t;
                    }

                    const elapsed = Math.min(3000, t - m.startT);
                    const dist = elapsed / 3000;

                    const s: Vector[] = [[m.bez[0].x, m.bez[0].y, m.bez[0].z], [m.bez[1].x, m.bez[1].y, m.bez[1].z], [m.bez[2].x, m.bez[2].y, m.bez[2].z]];
                    const b = bezier(s);
                    const p: Vector = b.b(dist);
                    m.img.position.set(p[0], p[1], p[2]);

                    m.img.rotation.x = m.startRotation.x - (m.startRotation.x - m.targetRotation.x) * dist;
                    m.img.rotation.y = m.startRotation.y - (m.startRotation.y - m.targetRotation.y) * dist;
                    m.img.rotation.z = m.startRotation.z - (m.startRotation.z - m.targetRotation.z) * dist;
                }
            });

            renderer.render(scene, camera);
        };
        animate(0);
        camera.position.x = 0;
        camera.position.y = 0;
        camera.position.z = 10;

        const floor = new THREE.Mesh(
            new THREE.BoxGeometry(30, 30, 0.1),
            new THREE.MeshPhongMaterial({ color: '0x111111', reflectivity: 1, side: THREE.DoubleSide })
        );
        floor.position.z = -5;
        //scene.add(floor);

        const directionalLight = new THREE.AmbientLight(0xffffff, 0.1);
        scene.add(directionalLight);
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.9);
        directionalLight2.position.x = 0;
        directionalLight2.position.y = 0;
        directionalLight2.position.z = 1;

        scene.add(directionalLight2);

        const light = new THREE.SpotLight('yellow', 0.5)
        light.position.set(1, 10, 50)
        //light.castShadow = true
        //light.shadow.camera.near = 0.5;
        //light.shadow.camera.far = 4000;
        //light.shadow.camera.fov = 30;
        light.angle = 0.05;
        light.penumbra = 1;
        light.target.position.set(0, 5, -0);
        scene.add(light);
        scene.add(light.target);
        light.target.updateMatrixWorld();
        const helper = new THREE.SpotLightHelper(light);
        //scene.add(helper);

        var ro = new ResizeObserver(entries => {
            for (let entry of entries) {
                const cr = entry.contentRect;

                //console.log('Element:', entry.target);
                //console.log(`Element size: ${cr.width}px x ${cr.height}px`);
                //console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
                camera.aspect = (cr.width) / (cr.height);
                camera.updateProjectionMatrix();

                renderer.setSize(cr.width, cr.height);
            }
        });

        // Observe one or multiple elements
        ro.observe(this.canvas.parentNode);

        //renderer.render(scene, camera);
        //animate();
        return scene;
    }

    ngAfterViewInit(): void {
        const slider = new Slider('#ex1', {
        });
        slider.on('change', (res: any) => {
            this.volume = res.newValue;
        });
        slider.setValue(this.volume);
        this.canvas = this.canvasElem.nativeElement;
        const scene = this.createThreeJsBox();
        const count = this.theme ? (this.theme.images ? this.theme.images.length : 0) : 0;
        const c_inc = 2.5; //22 / (count ? count : 0);

        setTimeout(() => {
            let c = -(count / 2) * 2.5;
            const w = 0 - c;
            const h = w / Math.tan(2 * 75 / 3 / 360 * 2 * Math.PI);
            this.theme ?.images ?.map(i => {
                const v: IVote = {
                    img: null,
                    startT: null,
                    startPos: new THREE.Vector3(c, 5, -5),
                    targetPos: new THREE.Vector3(c, 5, -1130),
                    startRotation: new THREE.Vector3(Math.random() * 4 * Math.PI / 2, Math.random() * 4 * Math.PI / 2, Math.random() * 4 * Math.PI / 2),
                    targetRotation: new THREE.Vector3(0, 0, 0),
                    bez: [
                        new THREE.Vector3(20, 30, -5),
                        new THREE.Vector3(-15 + c, 0, 5),
                        new THREE.Vector3(c, -5, 10 - h)
                    ],
                    ImageId: i.ImageId,
                    Title: i.Title,
                    SumVote: 0
                };
                c += c_inc;

                //v.img.src = 'https://foto.erikslada.se/uploads' + i.imgUrl;

                const textureLoader = new THREE.TextureLoader();
                textureLoader.crossOrigin = "use-credentials";
                const set = i.img.srcSet.split(',');
                const split = set[0].split(' ');
                textureLoader.load(split[0], t => {
                    //textureLoader.load('/assets/image.png', t => {
                    const s = t.image.width / t.image.height;
                    //console.log(t.image + ' ' + s);
                    let geometry: THREE.BoxGeometry;
                    if (s > 1) {
                        geometry = new THREE.BoxGeometry(2, 2 / s, 1);
                    } else {
                        geometry = new THREE.BoxGeometry(2 * s, 2, 1);
                    }
                    const uvs = geometry.attributes['uv'];
                    uvs.setX(0, 1);
                    uvs.setY(0, 1);
                    uvs.setX(1, 0);
                    uvs.setY(1, 1);
                    uvs.setX(2, 1);
                    uvs.setY(2, 0);
                    uvs.setX(3, 0);
                    uvs.setY(3, 0);
                    uvs.needsUpdate = true;

                    const mat = new THREE.MeshPhongMaterial({
                        color: "white",
                        map: t,
                    });
                    const cube = new THREE.Mesh(geometry, mat);
                    scene.add(cube);
                    v.img = cube;
                }, () => { }, () => {
                    textureLoader.load('/assets/image.png', t => {
                        const s = t.image.width / t.image.height;
                        //console.log(t.image + ' ' + s);
                        let geometry: THREE.BoxGeometry;
                        if (s > 1) {
                            geometry = new THREE.BoxGeometry(2, 2 / s, 1);
                        } else {
                            geometry = new THREE.BoxGeometry(2 * s, 2, 1);
                        }
                        const uvs = geometry.attributes['uv'];
                        uvs.setX(0, 1);
                        uvs.setY(0, 1);
                        uvs.setX(1, 0);
                        uvs.setY(1, 1);
                        uvs.setX(2, 1);
                        uvs.setY(2, 0);
                        uvs.setX(3, 0);
                        uvs.setY(3, 0);
                        uvs.needsUpdate = true;

                        const mat = new THREE.MeshPhongMaterial({
                            color: "white",
                            map: t,
                        });
                        const cube = new THREE.Mesh(geometry, mat);
                        scene.add(cube);
                        v.img = cube;
                    });
                });
                this.votes.push(v);
            });

            this.theme ?.voters.map(v => {
                v.votes.sort((a: any, b: any) => {
                    return a.VoteValue - b.VoteValue;
                });
            });
        }, 1000);
        //this.ctx.font = "48px serif";
    }

    presentVoter(u: number) {
        const getNextAudio = async (sentence: string) => {
            let audio = new SpeechSynthesisUtterance(sentence);
            audio.volume = this.volume / 100;
            audio.rate = 0.5;
            window.speechSynthesis.speak(audio);

            return new Promise(resolve => {
                audio.onend = resolve;
            });
        }

        if (this.theme && this.theme.voters) {
            const user = this.theme ?.voters[u];
            if (user) {
                //console.log('Här kommer poängen från ' + user.user.username);
                getNextAudio('Här kommer poängen från ' + user.user.username);
            }
        }
    }

    presentVoterQuick(u: number) {
        const getNextAudio = async (sentence: string) => {
            let audio = new SpeechSynthesisUtterance(sentence);
            audio.volume = this.volume / 100;
            audio.rate = 0.5;
            window.speechSynthesis.speak(audio);

            return new Promise(resolve => {
                audio.onend = resolve;
            });
        };

        setTimeout(async () => {
            if (this.theme && this.theme.voters) {
                const user = this.theme ?.voters[this.u];
                if (user) {
                    //console.log('Här kommer poängen från ' + user.user.username);
                    await getNextAudio('Här kommer poängen från ' + user.user.username);
                }
                user.votes.map((vote: any) => {
                    const v = this.votes.find(f => f.ImageId === vote.ImageId);
                    if (v) {
                        v.SumVote += vote.VoteValue;
                    }

                    this.votes.sort((a: any, b: any) => {
                        return b.SumVote - a.SumVote;
                    });

                });

                this.drawResult();
                this.u++;
                if (this.u < this.theme ?.voters ?.length) {
                    this.presentVoterQuick(0);
                }
            }
        }, 2000);
    }

    showResult() {
        //this.drawResult();
        this.disabled = true;

        this.u = 0;
        this.i = 0;

        this.presentVoter(0);
        this.addVote();
    }

    showResultQuick() {
        //this.drawResult();
        this.disabled = true;

        this.u = 0;
        this.i = 0;

        this.presentVoterQuick(0);
    }

    addVote() {
        const getNextAudio = async (sentence: string) => {
            const audio = new SpeechSynthesisUtterance(sentence);
            audio.volume = this.volume / 100;
            audio.rate = 0.5;
            window.speechSynthesis.speak(audio);

            return new Promise(resolve => {
                audio.onend = resolve;
            });
        }

        const self = this;
        setTimeout(async () => {
            if (self.theme && self.theme.voters) {
                const user = self.theme ?.voters[this.u];
                const vote = user.votes[this.i];
                const v = self.votes.find(f => f.ImageId === vote.ImageId);
                if (v) {
                    v.SumVote += vote.VoteValue;
                }
                //console.log(vote.VoteValue + ' poäng går till ' + v ?.Title);
                await getNextAudio(vote.VoteValue + ' poäng går till ' + v ?.Title);

                self.votes.sort((a: any, b: any) => {
                    return b.SumVote - a.SumVote;
                });

                self.drawResult();

                this.i++;
                if (self.i >= user.votes.length) {
                    self.i = 0;
                    self.u++;
                    self.presentVoter(this.u);
                }
                if (self.u < self.theme.voters.length) {
                    this.addVote();
                }
            }
            return new Promise(resolve => {
                resolve()
            });
        }, 2000);
    }

    drawResult() {
        let i = 0;
        const count = this.theme ?.images ?.length;
        const c_inc = 22 / (count ? (count - 3) : 0);
        const places: any[] = [{
            pos: 1,
            point: -1,
            imgs: [],
        }];
        const unplaced: IVote[] = [];
        const numberPositions = this.competition ? this.competition.comp.numberOfPositionedImages : 6;

        this.votes.map(m => {
            //console.log(m.Title + '  ' + m.SumVote);
            const place: any = places[places.length - 1];
            if (place.point === -1 || place.point === m.SumVote) {
                place.point = m.SumVote;
                place.imgs.push(m);
            } else if (m.SumVote > 0 && place.pos + place.imgs.length - 1 < numberPositions) {
                places.push({
                    point: m.SumVote,
                    imgs: [m],
                    pos: place.pos + place.imgs.length,
                });
            } else {
                m.pos = numberPositions;
                unplaced.push(m);
            }
            let dir = 0;
            m.startT = null;
            m.bez[0].set(m.img.position.x, m.img.position.y, m.img.position.z);
            m.startT = null;

            if (m.bez[2].y > m.bez[0].y) {
                dir = 1;
            } else if (m.bez[2].y < m.bez[0].y) {
                dir = -1;
            } else if (m.bez[2].x > m.bez[0].x) {
                dir = -1;
            } else if (m.bez[2].y < m.bez[0].y) {
                dir = 1
            }

            m.bez[1].set((m.bez[0].x + m.bez[2].x) / 2, (m.bez[0].y + m.bez[2].y) / 2, dir * 10);
            if (Math.abs(m.img.rotation.x) < 0.1 && Math.abs(m.img.rotation.y) < 0.1 && Math.abs(m.img.rotation.z) < 0.1) {
                m.startRotation.set(Math.round((Math.random() * 8) - 4) * Math.PI / 2, Math.round((Math.random() * 8) - 4) * Math.PI / 2, Math.round((Math.random() * 8) - 4) * Math.PI / 2);
            }
            i++;
        });

        let j = 0;
        let w10 = 0;
        places.forEach((p: any, index: number, a: any) => {
            if (p.pos === 1) {
                let w = 3 * p.imgs.length;
                let c = -w / 2;
                let cin = 0
                let o = 1.5;//p.imgs.length % 2 * 1.5;
                p.imgs.map((m: IVote) => {
                    const d = p.pos > (m.pos ?? 0) ? m.bez[0].z + 5 : m.bez[0].z - 15;
                    m.bez[1].z = d;
                    m.bez[2].set(c + cin * 3 + o, 5, 1)

                    m.pos = p.pos;
                    cin++;
                });
            } else if (p.pos === 2) {
                i = 0;
                p.imgs.map((m: IVote) => {
                    const d = p.pos > (m.pos ?? 0) ? m.bez[0].z + 5 : m.bez[0].z - 15;
                    m.bez[1].z = d;
                    m.bez[2].set(-3 - i * 3, 2, 1)

                    m.pos = p.pos;
                    i++;
                })
            } else if (p.pos === 3) {
                i = 0;
                p.imgs.map((m: IVote) => {
                    const d = p.pos > (m.pos ?? 0) ? m.bez[0].z + 5 : m.bez[0].z - 15;
                    m.bez[1].z = d;
                    m.bez[2].set(3 + i * 3, 2, 1)

                    m.pos = p.pos;
                    i++;
                })
            } else {
                p.imgs.map((m: IVote) => {
                    const d = p.pos > (m.pos ?? 0) ? m.bez[0].z + 5 : m.bez[0].z - 15;
                    m.bez[1].z = d;
                    m.bez[2].set(-10 + j * 2.5, -1, 1)

                    m.pos = p.pos;
                    j++;
                    w10 += 2.5;
                })

                if (index !== a.length - 1) {
                    w10 += 2.5;
                }
            }
        });

        const h10 = Math.max((w10 / 2) / Math.tan(2 * 75 / 3 / 360 * 2 * Math.PI), 6);
        let p10 = -w10 / 2;
        places.map((p: any) => {
            if (p.pos === 1) {
            } else if (p.pos === 2) {
            } else if (p.pos === 3) {
            } else {
                p.imgs.map((m: IVote) => {
                    m.bez[2].set(p10, -1, 10 - h10)

                    p10 += 2.5;
                })

                p10 += 2.5;
            }
        });

        i = 0;

        const ucount = unplaced.length;
        let c = -(ucount / 2) * 2.5;
        const w = 0 - c;
        const h = w / Math.tan(2 * 75 / 3 / 360 * 2 * Math.PI);
        const hh = h / 10 * 5

        unplaced.map((m: IVote) => {
            m.bez[2].set(c, -hh, 10 - h)

            c += 2.5;
        })
    }

    passBack() {
        this.activeModal.close();
    }
}
