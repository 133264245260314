interface Config {
  [key: string]: string;
  auth: 'session' | 'token';
}

// Session auth needs to use the same origin anyway
export const config: Config = {
  apiUrl: 'https://foto.erikslada.se/api',
  adminUrl: 'https://foto.erikslada.se/api/admin',
  authUrl: 'https://foto.erikslada.se/api/auth',
  auth: 'token'
};
