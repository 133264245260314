import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Isubcompetition } from '../../model/Isubcompetition';

@Component({
  selector: 'app-competition-theme-modal',
  templateUrl: './competition-theme-modal.component.html',
  styleUrls: ['./competition-theme-modal.component.scss']
})
export class CompetitionThemeModalComponent implements OnInit {
  public subcompetition: Isubcompetition = {
    CompetitionId: 0,
    Id: 0,
    SubName: '',
    SubCompTheme: '',
  };
  public validToSend = true;

  constructor(
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  passBack() {
    this.activeModal.close(this.subcompetition);
  }
}
