<div class="container">
    <h1>Fototävling</h1>
    <div class="row">
        <div class="col-12">
            <div style="
        border-width: 4px;
        border-style: solid;
        border-color: black;
        max-width: 1000px;
      ">
                <div>
                    <ngb-carousel class="carousel-fade" style="border-color: white; border-style: solid; border-width: 4px">
                        <ng-template ngbSlide>

                            <img src="assets/image.png" class="d-block w-100" alt="slide.Alt" />
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>

                            <img src="assets/image (2).png" class="d-block w-100" alt="slide.Alt" />
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>

                            <img src="assets/image (1).png" class="d-block w-100" alt="slide.Alt" />
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                        <ng-template ngbSlide>

                            <img src="assets/beb21a77-2996-4daf-97e5-dbd5eaba3c33.jfif" class="d-block w-100" alt="slide.Alt" />
                            <div class="carousel-caption">
                            </div>
                        </ng-template>
                    </ngb-carousel>
                </div>
            </div>
        </div>
    </div>

</div>