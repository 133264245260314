<div class="modal-body fullscreen">
    <div class="row">
        <div class="col-12">
  <button
    type="button"
    class="close black-modal-cross"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="row">
    <div class="col-12 col-lg-4" *ngIf="idx > 0" [ngClass]="{'col-lg-6': idx === images.length - 1}">
        <img
        src="https://foto.erikslada.se/uploads{{ images[idx -1].img.imgUrl }}"
        class="d-block"
        style="
          max-width: 100%;
          max-height: 90vh;
          margin-left: auto;
          margin-right: auto;
        "
        redZoom="https://foto.erikslada.se/uploads{{ images[idx -1].img.imgUrl }}"
        redZoomClass="red-zoom--style--overlay"
        redZoomBehavior="hover"
      />
      <button (click)="scrollLeft()" class="carousel-control-prev"><span class="carousel-control-prev-icon"></span></button>
    </div>
    <div class="col-12 col-lg-4" [ngClass]="{'col-lg-6': idx === 0 || idx === images.length - 1}">
        <img
        src="https://foto.erikslada.se/uploads{{ images[idx].img.imgUrl }}"
        class="d-block"
        style="
          max-width: 100%;
          max-height: 90vh;
          margin-left: auto;
          margin-right: auto;
        "
        redZoom="https://foto.erikslada.se/uploads{{ images[idx].img.imgUrl }}"
        redZoomClass="red-zoom--style--overlay"
        redZoomBehavior="hover"
      />
      <button (click)="swapLeft()" *ngIf="idx > 0" class="swap-control-prev"><fa-icon [icon]="['fas', 'exchange-alt']"></fa-icon></button>
      <button (click)="swapRight()" *ngIf="idx < images.length-1" class="swap-control-next"><fa-icon [icon]="['fas', 'exchange-alt']"></fa-icon></button>
    </div>
    <div class="col-12 col-lg-4" *ngIf="idx < images.length-1" [ngClass]="{'col-lg-6': idx === 0}">
        <img
        src="https://foto.erikslada.se/uploads{{ images[idx +1].img.imgUrl }}"
        class="d-block"
        style="
          max-width: 100%;
          max-height: 90vh;
          margin-left: auto;
          margin-right: auto;
        "
        redZoom="https://foto.erikslada.se/uploads{{ images[idx +1].img.imgUrl }}"
        redZoomClass="red-zoom--style--overlay"
        redZoomBehavior="hover"
      />
      <button (click)="scrollRight()" class="carousel-control-next"><span class="carousel-control-next-icon"></span></button>
    </div>
</div>
