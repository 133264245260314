import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from '../config/config';
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';

@Injectable({
  providedIn: 'root'
})
export class CompetitionImagesService {

  constructor(private http: HttpClient) { }

  fetchCompetitions() {
    return this.http.get<any>(`${config['apiUrl']}/competition/fetchCompetitionImage`);
  }

  fetchSubCompetitions(Id: number) {
    return this.http.get<any>(`${config['apiUrl']}/competition/fetchSubCompetition/` + Id, );
  }

  fetchCompetitionImages(sub: Isubcompetition) {
    return this.http.get<ICompetitionImage[]>(`${config['apiUrl']}/competition/fetchCompetitionImages/` + sub.CompetitionId + '/' + sub.Id);
  }

  upload(file: any) {
    let headers = new HttpHeaders();

    let options = { headers: headers };
    return this.http.post<ICompetitionImage>(`${config['apiUrl']}/competition/addImage/`, file);
  }

  deleteImage(image: ICompetitionImage) {
    return this.http.post<ICompetitionImage>(`${config['apiUrl']}/competition/deleteRestImage/`, image);
  }

  editImage(image: ICompetitionImage, title: string, comment: string) {
    return this.http.post<ICompetitionImage>(`${config['apiUrl']}/competition/editImage/`, { image, title, comment });
  }
}
