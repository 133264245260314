import { Component, OnInit } from "@angular/core";
import { Toast } from "../service/toast.interface";

import { ToastService } from "../service/toast.service";
@Component({
    selector: "app-toast-config",
    templateUrl: "toast-config.component.html",
    styles: []
})
export class ToastConfigComponent implements OnInit {
    toasts: Toast[] = [];

    constructor(private toaster: ToastService) { }

    ngOnInit() {
        this.toaster.toast$.subscribe((toast) => {
            if (toast) {
                this.toasts = [toast, ...this.toasts];
                setTimeout(() => this.toasts.pop(), toast.delay || 6000);
            }
        });
    }

    remove(index: number) {
        this.toasts = this.toasts.filter((v, i) => i !== index);
        //this.toasts.splice(index, 1);
    }
}
