import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditorComponent } from '@tinymce/tinymce-angular';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.scss']
})
export class CommentModalComponent implements OnInit {
  @Input() formattedText: string = '';
  @Input() IsAnonymous: boolean = false;
  validToSend: boolean = true;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  passBack() {
    this.activeModal.close({ formattedText: this.formattedText, IsAnonymous: this.IsAnonymous })
  }
}
