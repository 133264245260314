import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { config } from '../config/config';
import { LoginRequest } from '../model/login-request';
import { User } from '../model/user';
import { AuthStrategy } from './auth-strategy';
import { SessionAuthStrategy } from './session-auth-strategy';
import { JwtAuthStrategy } from './jwt-auth-strategy';
import { Token } from "../model/token";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly LOGIN_PATH = '/login';
  public readonly CONFIRM_PATH = '/confirm';
  public readonly INITIAL_PATH = '/app/dashboard';
  private auth: JwtAuthStrategy;
  public loggedIn = new BehaviorSubject(false);
  public user?: User;
  public logoutTime: any;

  loggedInChange: Subject<boolean> = new Subject<boolean>();
  userChange: Subject<User | undefined> = new Subject<User | undefined>();

  constructor(
    //private router: Router,
    private http: HttpClient //@Inject(SessionAuthStrategy) private auth: AuthStrategy<any>
  ) {
    this.auth = new JwtAuthStrategy();
    this.loggedInChange.subscribe((value) => {
      this.loggedIn.next(value);
    });
    this.userChange.subscribe((value) => {
      this.user = value;
    });

    this.auth.getCurrentUser().subscribe((d: any) => {
      if (d) {
        this.logoutTime = new Date(d.exp * 1000);
        //console.log('Step 1: ' + this.logoutTime + ' ' + d);
        const u = JSON.parse(d.aud);
        this.loggedInChange.next(this.logoutTime > new Date());
        this.userChange.next(u);
      } else {
        this.userChange.next(undefined);
      }
    });

    setTimeout(() => {
      this.auth.getCurrentUser().subscribe((d: any) => {
        if (d) {
          this.logoutTime = new Date(d.exp * 1000);
          //console.log('Step 2: ' + this.logoutTime + ' ' + d);
          const u = JSON.parse(d.aud);
          this.loggedInChange.next(this.logoutTime > new Date());
          this.userChange.next(u);
        } else {
          this.userChange.next(undefined);
        }
      });
    }, 1000);

    /*this.http.get<any>(`${config['authUrl']}/user`).subscribe((data) => {
      console.log(data);
      this.auth.doLoginUser(data);
      //this.loggedIn = !!data.confirmed;
      this.loggedInChange.next(!!data.confirmed);
      this.userChange.next(data);
    });*/
  }

  signup(user: User): Observable<void> {
    return this.http.post<any>(`${config['authUrl']}/signup`, user).pipe(
      tap((data: any) => {
        if (data) {
          this.auth.doLoginUser(data);
        } else {
          this.auth.doLogoutUser();
        }
        this.auth.getCurrentUser().subscribe((d: any) => {
          this.logoutTime = new Date(d.exp * 1000);
          //console.log('Step 3: ' + this.logoutTime + ' ' + d);
          const u = JSON.parse(d.aud);
          this.loggedInChange.next(this.logoutTime > new Date());
          this.userChange.next(u);
        });
        //this.loggedIn = !!data.confirmed;
      })
    );
  }

  confirm(email: string, code: string): Observable<void> {
    return this.http.post<any>(`${config['authUrl']}/confirm?`, { email, code });
  }

  login(loginRequest: LoginRequest): Observable<User> {
    return this.http.post<any>(`${config['authUrl']}/login`, loginRequest).pipe(
      tap((data) => {
        this.handleLogin(data);
        //this.loggedIn = !!data.confirmed;
      })
    );
  }

  public handleLogin(data: any) {
    if (data) {
      this.auth.doLoginUser(data);
    } else {
      this.auth.doLogoutUser();
    }
    this.auth.getCurrentUser().subscribe((d: any) => {
      this.logoutTime = new Date(d.exp * 1000);
      //console.log('Step 4: ' + this.logoutTime + ' ' + d);
      const u = JSON.parse(d.aud);
      this.loggedInChange.next(this.logoutTime > new Date());
      this.userChange.next(u);
    });
  }

  sendRecover(email: string) {
    return this.http
      .post<any>(`${config['authUrl']}/sendrecover/`, { email })
      .pipe((response) => response);
  }

  public updatePassword(hash: string, pwd: string) {
    return this.http
      .post<any>(`${config['authUrl']}/updaterecover/`, { hash: hash, pwd: pwd })
      .pipe((response) => response);
  }

  logout() {
    return this.http.get<any>(`${config['authUrl']}/logout`).pipe(
      tap(() => {
        this.doLogoutUser();
        this.loggedInChange.next(false);
        this.userChange.next(undefined);
        //this.user = undefined;
        //this.loggedIn = false;
      })
    );
  }

  isLoggedIn$(): Observable<boolean> {
    return this.loggedIn.asObservable(); //!!user && !!user.confirmed;
  }

  getCurrentUser$(): Observable<User> {
    return this.auth.getCurrentUser();
  }

  getToken(): string | undefined | null {
    return this.auth.getToken();
  }

  private doLogoutUser() {
    this.auth.doLogoutUser();
    this.loggedIn.next(false);
  }

  public doLoginUser(data: any) {
    this.auth.doLoginUser(data);
    this.loggedIn.next(true);
  }
}
