import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../authentication/auth-service.service';
import { CompetitionService } from '../competition.service';
import { CompetionModalComponent } from '../competion-modal/competion-modal.component'
import { InvitationModalComponent } from '../invitation-modal/invitation-modal.component'
import { CompetitionThemeModalComponent } from '../competition-theme-modal/competition-theme-modal.component'
import { Icompetition } from '../../model/Icompetition';
import { Isubcompetition } from '../../model/Isubcompetition';
import { IInvitation } from '../../model/IInvitation';
import { YesNoModalComponent } from '../../shared/yes-no-modal/yes-no-modal.component';

@Component({
  selector: 'app-competition',
  templateUrl: './competition.component.html',
  styleUrls: ['./competition.component.scss']
})
export class CompetitionComponent implements OnInit {
  public competitions: Icompetition[] = [];

  constructor(private competitionService: CompetitionService, private modalService: NgbModal, protected authService: AuthService, private router: Router) {
    if (!this.authService.loggedIn) {
      this.router.navigate(['/home']);
    }
    this.competitionService.fetchCompetitions().subscribe((data: Icompetition[] | any) => {
      data.map((c: Icompetition) => {
        this.competitionService.fetchSubCompetitions(c.Id).subscribe((s: Isubcompetition[] | any) => {
          c.subCompetitions = s;
        })
        this.competitionService.fetchInvitations(c.Id).subscribe((i: any) => {
          c.invitations = i;
        });
      });
      this.competitions = data;
    });
  }

  ngOnInit(): void {
    this.authService.loggedIn.subscribe((l: boolean) => {
      if (!l) {
        this.router.navigate(['/home']);
      }
    });

  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  copyInvitation(i: IInvitation) {
    //console.log(i);
    this.copyMessage('https://foto.erikslada.se/invitation/' + i.Hash);
  }

  getDate(dat: any) {
    var d = (new Date(dat).toISOString()).split('T');
    d[2] = d[2] + ',';

    return d[0];
  }

  onAddClick() {
    const modalRef = this.modalService.open(CompetionModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    let userItem: Icompetition = {
      Id: 0,
      CompetitionName: '',
      EndoParticipation: '',
      EndOfVoting: '',
      participantCanAddImage: 0,
      numberOfVotes: 0,
      numberOfPositionedImages: 0,
      Logo: '',
      VisibleToVoterFrom: '',
      PubliclyVisible: 0,
      PubliclyVisibleFrom: '',
      subCompetitions: null,
      invitations: null
    };
    modalRef.componentInstance.competition = userItem;

    modalRef.result.then((result) => {
      //this.fronts.push(result);
      result.participantCanAddImage = result.participantCanAddImage ? 1 : 0;
      this.competitionService.addCompetition(result).subscribe((data: Icompetition) => {
        this.competitionService.fetchSubCompetitions(data.Id).subscribe((s: Isubcompetition[] | any) => {
          data.subCompetitions = s;
        });
        this.competitions.push(data);
      });
    }, (error) => { console.log(error) });
  }

  onEditClick(competitionItem: Icompetition) {
    const modalRef = this.modalService.open(CompetionModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    let uItem: Icompetition = {
      Id: competitionItem.Id,
      CompetitionName: competitionItem.CompetitionName,
      EndoParticipation: competitionItem.EndoParticipation.split(' ')[0],
      EndOfVoting: competitionItem.EndOfVoting.split(' ')[0],
      participantCanAddImage: competitionItem.participantCanAddImage,
      numberOfVotes: competitionItem.numberOfVotes,
      numberOfPositionedImages: competitionItem.numberOfPositionedImages,
      Logo: competitionItem.Logo,
      VisibleToVoterFrom: competitionItem.VisibleToVoterFrom,
      PubliclyVisible: competitionItem.PubliclyVisible,
      PubliclyVisibleFrom: competitionItem.PubliclyVisibleFrom,
      subCompetitions: null,
      invitations: null
    };
    modalRef.componentInstance.competition = uItem;

    modalRef.result.then((result) => {
      result.participantCanAddImage = result.participantCanAddImage ? 1 : 0;
      this.competitionService.updateCompetition(result).subscribe(data => {
        competitionItem.Id = data.Id;
        competitionItem.CompetitionName = data.CompetitionName;
        competitionItem.EndoParticipation = data.EndoParticipation;
        competitionItem.EndOfVoting = data.EndOfVoting;
        competitionItem.participantCanAddImage = data.participantCanAddImage;
        competitionItem.numberOfVotes = data.numberOfVotes;
      });
    }, (error) => { console.log(error) });
  }

  onCreateInvitationClick(competition: Icompetition) {
    const modalRef = this.modalService.open(InvitationModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    let invitation: IInvitation = {
      Hash: '',
      Competition: competition.Id,
      ValidTo: '',
      CanVote: 0,
      CanParticipate: 0,
      tag: ''
    };
    modalRef.componentInstance.invitation = invitation;

    modalRef.result.then((result) => {
      //this.fronts.push(result);
      //result.participantCanAddImage = result.participantCanAddImage ? 1 : 0;
      this.competitionService.createInvitation(result).subscribe((data: IInvitation) => {
        if (competition) {
          if (!competition.invitations) {
            competition.invitations = [];
          }
          competition ?.invitations ?.push(data);
        }
        //competition.push(data);
      });
    }, (error) => { console.log(error) });
  }

  onDeleteClick(competition: Icompetition) {
    const modalRef = this.modalService.open(YesNoModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    modalRef.componentInstance.title = 'Ta bort bild';
    modalRef.componentInstance.modalText = 'Vill du ta bort ' + competition.CompetitionName + '?';

    modalRef.result.then((result: boolean) => {
      if (result) {
        this.competitionService.deleteCompetition(competition).subscribe((d: Icompetition) => {

        }, (error: any) => {
          console.log(error);
        });
      }
    });

  }
  onAddSubClick(competitionItem: Icompetition) {
    const modalRef = this.modalService.open(CompetitionThemeModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    let subItem: Isubcompetition = {
      Id: 0,
      CompetitionId: competitionItem.Id,
      SubName: '',
      SubCompTheme: ''
    };
    modalRef.componentInstance.subcompetition = subItem;

    modalRef.result.then((result: Isubcompetition) => {
      this.competitionService.addSubCompetition(result).subscribe((data: Isubcompetition) => {
        competitionItem.subCompetitions ?.push(data);
      });
    }, (error) => { console.log(error) });
  }

  onEditSubClick(subcompetitionItem: Isubcompetition) {
    const modalRef = this.modalService.open(CompetitionThemeModalComponent, {
      backdrop: 'static',
      size: 'lg',
    });
    let subItem: Isubcompetition = {
      Id: subcompetitionItem.Id,
      CompetitionId: subcompetitionItem.CompetitionId,
      SubName: subcompetitionItem.SubName,
      SubCompTheme: subcompetitionItem.SubCompTheme
    };
    modalRef.componentInstance.subcompetition = subItem;

    modalRef.result.then((result: Isubcompetition) => {
      this.competitionService.updateSubCompetition(result).subscribe((data: Isubcompetition) => {
        subcompetitionItem.Id = data.Id;
        subcompetitionItem.CompetitionId = data.CompetitionId;
        subcompetitionItem.SubName = data.SubName;
        subcompetitionItem.SubCompTheme = data.SubCompTheme;
      });
    }, (error) => { console.log(error) });
  }
}
