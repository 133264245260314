import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../authentication/auth-service.service';
import { CompetitionImagesService } from './competition-images.service'
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';
import { ModalComponent } from './modal/modal.component';
import { TitleModalComponent } from './title-modal/title-modal.component';
import { YesNoModalComponent } from '../shared/yes-no-modal/yes-no-modal.component';
import { ToastService } from '../service/toast.service';

@Component({
    selector: 'app-competition-images',
    templateUrl: './competition-images.component.html',
    styleUrls: ['./competition-images.component.scss']
})
export class CompetitionImagesComponent implements OnInit {
    requiredFileType = "*.jpg";
    public competitions: Icompetition[] = [];

    constructor(private competitionService: CompetitionImagesService, private modalService: NgbModal, protected authService: AuthService, private toaster: ToastService, private router: Router) {
        if (!this.authService.loggedIn) {
            this.router.navigate(['/home']);
        }
    }


    async ngOnInit(): Promise<void> {
        const t: any = await this.authService.getCurrentUser$().toPromise();
        const u = JSON.parse(t.aud);

        this.authService.loggedIn.subscribe((l: boolean) => {
            if (!l) {
                this.router.navigate(['/home']);
            }
        });

        this.competitionService.fetchCompetitions().subscribe((data: Icompetition[] | any) => {
            data.map((c: Icompetition) => {
                this.competitionService.fetchSubCompetitions(c.Id).subscribe((s: Isubcompetition[] | any) => {
                    c.subCompetitions = s;
                    s.map((m: Isubcompetition) => {
                        this.competitionService.fetchCompetitionImages(m).subscribe((i: ICompetitionImage[]) => {
                            m.images = [];
                            if (m && m.images) {
                                i.map(im => {
                                    if (u.iduser === im.UserId) {
                                        if (m && m.images) {
                                            m.images.push(im);
                                        }
                                    }
                                })
                            }
                        })
                    });
                })
            });
            this.competitions = data;
        });
    }

    onAddClick(sub: Isubcompetition) {
        const modalRef = this.modalService.open(ModalComponent, {
            backdrop: 'static',
            size: 'lg',
        });

        modalRef.result.then(async (result: any) => {
            if (result.file) {
                //this.fileName = file.name;
                const formData = new FormData();
                formData.set("file", result.file, result.file.name);
                formData.set('submit', 'true')
                formData.set('title', result.title);
                formData.set('comment', result.comment);
                formData.set('competition', JSON.stringify(sub));
                const t: any = await this.authService.getCurrentUser$().toPromise();
                const u = JSON.parse(t.aud);
                formData.set('userId', JSON.stringify(u ?.iduser));
                this.competitionService.upload(formData).subscribe((d: ICompetitionImage) => {
                    sub.images ?.push(d);
                    this.toaster.show('success', 'Uppladdningen lyckades', '');
                }, (error: any) => {
                    console.log(error);
                    this.toaster.show('error', 'Uppladdningen misslyckades', error.error.error);
                });
            }
        });

    }

    onRemoveClick(image: ICompetitionImage) {
        const modalRef = this.modalService.open(YesNoModalComponent, {
            backdrop: 'static',
            size: 'lg',
        });
        modalRef.componentInstance.title = 'Ta bort bild';
        modalRef.componentInstance.modalText = 'Vill du ta bort ' + image.Title + '?';

        modalRef.result.then((result: boolean) => {
            if (result) {
                this.competitionService.deleteImage(image).subscribe((d: ICompetitionImage) => {

                }, (error: any) => {
                    console.log(error);
                });
            }
        });
    };

    onEditClick(image: ICompetitionImage) {
        const modalRef = this.modalService.open(TitleModalComponent, {
            backdrop: 'static',
            size: 'lg',
        });

        modalRef.componentInstance.title = image.Title;
        modalRef.componentInstance.comment = image.Comment;
        modalRef.result.then((result: any) => {
            if (result) {
                this.competitionService.editImage(image, result.title, result.comment).subscribe((d: ICompetitionImage) => {
                    image.Title = d.Title;
                }, (error: any) => {
                    console.log(error);
                });
            }
        });
    };
}

