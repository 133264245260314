import { Component } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LogoutModalComponent } from './auth/logout-modal/logout-modal.component';
import { Subscription } from 'rxjs';
import { EventBusService } from './shared/event-bus.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent {
  eventBusSub?: Subscription;
  showSpinner = false;
  title = 'angular_photo-contest';
  modalOption: NgbModalOptions = {};

  constructor(
    private eventBusService: EventBusService,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit(): void {
    // ...

    this.eventBusSub = this.eventBusService.on('logout', (value: any) => {
      this.logout(value);
    });
  }

  logout(startTime: number): void {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;

    //console.log('Utloggad');
    const modalRef = this.modalService.open(
      LogoutModalComponent,
      this.modalOption
    );

    modalRef.componentInstance.startTime = startTime;
    modalRef.result.then((result) => { });
  }
}
