import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RedZoomModule } from 'ngx-red-zoom';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { EventBusService } from './shared/event-bus.service';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AppHeaderComponent } from './app-header/app-header.component';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxDragDropModule } from 'ngx-dragdrop';
import {
  FontAwesomeModule,
  FaIconLibrary,
} from '@fortawesome/angular-fontawesome';
import {
  faPlus,
  faPen,
  faCheck,
  faBan,
  faMinus,
  faShoppingCart,
  faTrash,
  faEnvelope,
  faCopy,
  faCog,
  faExchangeAlt,
  faArrowCircleLeft,
  faArrowCircleRight,
  faFilePdf,
  faFileWord,
  faPlay,
  faFastForward,
  faProjectDiagram
} from '@fortawesome/free-solid-svg-icons';
import { UserModalComponent } from './users/user-modal/user-modal.component';
import { LogoutModalComponent } from './auth/logout-modal/logout-modal.component';
import { CompetitionComponent } from './competition/competition/competition.component';
import { CompetionModalComponent } from './competition/competion-modal/competion-modal.component';
import { CompetitionThemeModalComponent } from './competition/competition-theme-modal/competition-theme-modal.component';
import { LoginInterceptor } from './login.interceptor';
import { InvitationModalComponent } from './competition/invitation-modal/invitation-modal.component';
import { AuthService } from './authentication/auth-service.service';
import { InvitationComponent } from './invitation/invitation.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { CompetitionImagesComponent } from './competition-images/competition-images.component';
import { CompetitionVotesComponent } from './competition-votes/competition-votes.component';
import { ModalComponent } from './competition-images/modal/modal.component';
import { CommentModalComponent } from './competition-votes/comment-modal/comment-modal.component';
import { CompareModalComponent } from './competition-votes/compare-modal/compare-modal.component';
import { SliderModalComponent } from './slider-modal/slider-modal.component';
import { EntervoteModalComponent } from './competition-votes/entervote-modal/entervote-modal.component';
import { CompetitionResultComponent } from './competition-result/competition-result.component';
import { ResultModalComponent } from './competition-result/result-modal/result-modal.component';
import { CompetitionPresentationComponent } from './competition-presentation/competition-presentation.component';
import { ToastConfigComponent } from "./toast/toast-config.component";
import { ToastComponent } from "./toast/toast.component";
import { SubCompetitionComponent } from './competition-result/sub-competition/sub-competition.component';
import { ImagesResultComponent } from './competition-result/images-result/images-result.component';
import { YesNoModalComponent } from './shared/yes-no-modal/yes-no-modal.component';
import { VideoplayerComponent } from './help/videoplayer/videoplayer.component';
import { TitleModalComponent } from './competition-images/title-modal/title-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    HomeComponent,
    UsersComponent,
    UserModalComponent,
    CompetitionComponent,
    CompetionModalComponent,
    CompetitionThemeModalComponent,
    InvitationModalComponent,
    InvitationComponent,
    RecoveryComponent,
    CompetitionImagesComponent,
    CompetitionVotesComponent,
    ModalComponent,
    CommentModalComponent,
    CompareModalComponent,
    SliderModalComponent,
    EntervoteModalComponent,
    CompetitionResultComponent,
    ResultModalComponent,
    LogoutModalComponent,
    CompetitionPresentationComponent,
    ToastConfigComponent,
    ToastComponent,
    SubCompetitionComponent,
    ImagesResultComponent,
    YesNoModalComponent,
    VideoplayerComponent,
    TitleModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    FontAwesomeModule,
    NgxDragDropModule,
    RedZoomModule,
    EditorModule,
    TabsModule.forRoot(),
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoginInterceptor,
      multi: true,
    },],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIconPacks([
      faPlus,
      faPen,
      faCheck,
      faBan,
      faMinus,
      faShoppingCart,
      faTrash,
      faEnvelope,
      faCopy,
      faCog,
      faExchangeAlt,
      faArrowCircleLeft,
      faArrowCircleRight,
      faFilePdf,
      faFileWord,
      faPlay,
      faFastForward,
      faProjectDiagram
    ] as any);
  }
}
