export type Vector = number[];

function add(u: Vector, v: Vector): Vector {
    return u.map((x, i) => x + v[i])
}

function sub(u: Vector, v: Vector): Vector {
    return u.map((x, i) => x - v[i])
}

function lerp(x: number, y: number, t: number): number {
    return (1 - t) * x + t * y
}

function lerpV(x: Vector, y: Vector, t: number): Vector {
    return x.map((v, i) => lerp(v, y[i], t))
}

type Interpolator = (t: number) => Vector

export function bezier(points: Vector[]): { b: Interpolator, db_dt: Interpolator } {
    if (points.length === 0) throw new Error('no point to interpolate')

    function _bezier(s: number, e: number, t: number, cachedValue: Record<number, Vector> = {}): Vector {
        return (cachedValue[s * points.length + e] ??=
            s === e
                ? points[s]
                : e - s === 1
                    ? lerpV(points[s], points[e], t)
                    : lerpV(_bezier(s, e - 1, t, cachedValue), _bezier(s + 1, e, t, cachedValue), t))
    }

    function _bezierPrime(s: number, e: number, t: number, cache: { b: Record<number, Vector>, db_dt: Record<number, Vector> }): Vector {
        return (cache.db_dt[s * points.length + e] ??=
            s === e
                ? points[s].map(() => 0)
                : e - s === 1
                    ? sub(points[e], points[s])
                    : add(
                        lerpV(
                            _bezierPrime(s, e - 1, t, cache),
                            _bezierPrime(s + 1, e, t, cache),
                            t
                        ),
                        sub(
                            _bezier(s + 1, e, t, cache.b),
                            _bezier(s, e - 1, t, cache.b)
                        )
                    )
      )
    }

    return {
        b: (t: number) => _bezier(0, points.length - 1, t),
        db_dt: (t: number) => _bezierPrime(0, points.length - 1, t, { b: {}, db_dt: {} })
    }
}