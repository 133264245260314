import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { IVoteContainer } from '../../model/IVoteContainer';
import { ICompetitionImageVote } from '../../model/ICompetitionImageVote';

@Component({
  selector: 'app-compare-modal',
  templateUrl: './compare-modal.component.html',
  styleUrls: ['./compare-modal.component.scss']
})
export class CompareModalComponent implements OnInit {
  @Input() public images: IVoteContainer[] = [];
  @Input() public idx: number = 0;
  @ViewChild(NgbCarousel, { static: true }) carousel?: NgbCarousel;

  constructor(public activeModal: NgbActiveModal) { }

  scrollLeft() {
    this.idx--;
  }

  scrollRight() {
    this.idx++;
  }

  swapLeft() {
    const vote: ICompetitionImageVote | null = this.images[this.idx].vote;
    if (vote) {
      const swap: ICompetitionImageVote | null = this.images[this.idx - 1].vote;
      if (swap) {
        const t = vote.SortOrder;
        vote.SortOrder = swap.SortOrder;
        swap.SortOrder = t;
      }
    }

    this.sortSwap();
  }

  swapRight() {
    const vote: ICompetitionImageVote | null = this.images[this.idx].vote;
    if (vote) {
      const swap: ICompetitionImageVote | null = this.images[this.idx + 1].vote;
      if (swap) {
        const t = vote.SortOrder;
        vote.SortOrder = swap.SortOrder;
        swap.SortOrder = t;
      }
    }

    this.sortSwap();
  }

  sortSwap() {
    this.images.sort((a, b) => {
      if ((a.vote ? a.vote.SortOrder : 0) < (b.vote ? b.vote.SortOrder : 0)) {
        return -1
      } else {
        return 1
      }
    });
  }

  onLeftClick(v: IVoteContainer) {
  }

  onRightClick(v: IVoteContainer) {
    const vote: ICompetitionImageVote | null = v.vote;
    if (vote) {
      const s: IVoteContainer | undefined = this.images.find((f: IVoteContainer) => f.vote ?.SortOrder === vote.SortOrder + 1);
      if (s && s.vote) {
        const t = vote.SortOrder;
        vote.SortOrder = s.vote.SortOrder;
        s.vote.SortOrder = t;

        //console.log(JSON.stringify(v));
      }
    }

    this.images.sort((a, b) => {
      if ((a.vote ? a.vote.SortOrder : 0) < (b.vote ? b.vote.SortOrder : 0)) {
        return -1
      } else {
        return 1
      }
    });
  }

  ngOnInit(): void {
  }

}
