import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-videoplayer',
    templateUrl: './videoplayer.component.html',
    styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent implements OnInit {
    @ViewChild('videoplayer', { static: true }) videoPlayer!: ElementRef;

    videofile = '/assets/registrera.mp4';

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.route.url.subscribe(params => {
            console.log(params[1].path);
            //element = 
            if (params[1].path == 'register') {
                this.videoPlayer ?. nativeElement.pause();
                this.videofile = '/assets/registrera.mp4';
                this.videoPlayer ?. nativeElement.load();
                this.videoPlayer ?. nativeElement.play();
            } else if (params[1].path == 'addimage') {
                this.videoPlayer ?. nativeElement.pause();
                this.videofile = '/assets/addimage.mp4';
                this.videoPlayer ?. nativeElement.load();
                this.videoPlayer ?. nativeElement.play();
            } else if (params[1].path == 'vote') {
                this.videoPlayer ?. nativeElement.pause();
                this.videofile = '/assets/vote.mp4';
                this.videoPlayer ?. nativeElement.load();
                this.videoPlayer ?. nativeElement.play();
            }
        })
    }

}
