import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../authentication/auth-service.service';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {
  pwd: string = '';
  pwd2: string = '';
  valid = false;
  signupError: string | null = null;
  hash: string | undefined | null = '';
  recovered: boolean = false;
  loggedIn: boolean = false;

  constructor(private route: ActivatedRoute, public authService: AuthService) { }

  ngOnInit(): void {
    this.authService.loggedIn.subscribe(l => this.loggedIn = l);
    this.hash = this.route.snapshot ?.url[1].path;
    this.hash = this.route.snapshot.paramMap.get('hash');
  }

  onChange() {
    setTimeout(() => {
      this.valid = !!this.pwd && this.pwd.length > 2 && this.pwd === this.pwd2;
    }, 1);
  }

  onRegister() {
    if (this.hash) {
      this.authService.updatePassword(this.hash, this.pwd).subscribe(l => { this.recovered = l });
    }
  }
}
