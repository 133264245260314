<div class="container">
    <div class="card" style="margin-bottom: 15px">
        <div class="card-header">
            <div class="row" style="margin-left: '15px';margin-right:'15px'">

                <div class="col-5"><strong>Tävlingsnamn                </strong>
                </div>
                <div class="col-2"><strong>Sista dag för inlämnande                </strong>
                </div>
                <div class="col-2"><strong>Sista dag för röstning                </strong>
                </div>
                <div class="col-1">
                    <Button (click)="onAddClick()" [disabled]="!authService.user?.globaladmin"><fa-icon [icon]="['fas', 'plus']"></fa-icon></Button>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="card" *ngFor="let competition of competitions">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5">{{competition.CompetitionName}}</div>
                        <div class="col-2">{{competition.EndoParticipation | date:'yyyy-MM-dd'}}</div>
                        <div class="col-2">{{competition.EndOfVoting | date:'yyyy-MM-dd'}}</div>
                        <div class="col-2">
                            <Button (click)="onEditClick(competition)"><fa-icon [icon]="['fas', 'pen']"></fa-icon></Button>
                            <Button (click)="onAddSubClick(competition)"><fa-icon [icon]="['fas', 'plus']"></fa-icon></Button>
                            <Button (click)="onCreateInvitationClick(competition)"><fa-icon [icon]="['fas', 'envelope']"></fa-icon></Button>
                            <Button (click)="onDeleteClick(competition)" [disabled]="!authService.user?.globaladmin"><fa-icon [icon]="['fas', 'minus']"></fa-icon></Button>
                        </div>
                    </div>
                    <div class='row' *ngFor="let invitation of competition.invitations">
                        <div class="col-5">{{invitation.ValidTo}}</div>
                        <div class="col-3">&nbsp;<span *ngIf="invitation.CanVote">Kan rösta</span></div>
                        <div class="col-3">&nbsp;<span *ngIf="invitation.CanParticipate">Kan Delta</span></div>
                        <div class="col-1"><Button (click)='copyInvitation(invitation)'><fa-icon [icon]="['fas', 'copy']"></fa-icon></Button></div>
                        <div class="col-12">/invitation/{{invitation.Hash}}</div>
                    </div>
                    <div class="row">
                        <div class="col-5"><strong>Deltävlingsnamn</strong></div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row" *ngFor="let sub of competition.subCompetitions">
                        <div class="col-5">{{sub.SubName}}</div>
                        <div class="col-2">
                            <Button (click)="onEditSubClick(sub)"><fa-icon [icon]="['fas', 'pen']"></fa-icon></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>