<div class="container">
    <div class="card" style="margin-bottom: 15px">
        <div class="card-header">
            <div class="row" style="margin-left: '15px';margin-right:'15px'">

                <div class="col-5"><strong>Tävlingsnamn</strong>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="card" *ngFor="let competition of competitions">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5">{{competition.comp.CompetitionName}}</div>
                        <div class="col-2">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <tabset>
                        <tab *ngFor="let sub of competition.themes let i = index" heading="{{sub.theme.SubName}}">
                            <div class="card" style="margin-bottom: 15px">
                                <div class="card-header">
                                    <div class="row" style="margin-left: '15px';margin-right:'15px'">
                                        <div class="col-12" [innerHtml]="sub.theme.SubCompTheme"></div>

                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row" *ngFor="let vote of sub.images" style="padding-bottom:15px;">
                                        <div class="col-4">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="frame">
                                                        <div class="mat">
                                                    <img src="https://foto.erikslada.se/uploads/{{vote.img.imgUrl}}" 
                                                        redZoom="https://foto.erikslada.se/uploads/{{vote.img.imgUrl}}"
                                                        redZoomClass="red-zoom--style--window"
                                                        redZoomBehavior="hover"
                                                        width="100%"
                                                    >
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="col-12" *ngIf="vote.img.iso">ISO: {{vote.img.iso}}</div>
                                                <div class="col-12" *ngIf="vote.img.aperture">Bländare: {{vote.img.aperture}}</div>
                                                <div class="col-12" *ngIf="vote.img.shutter">Slutare: {{vote.img.shutter}}</div>
                                                <div class="col-12" *ngIf="vote.img.focal">Brännvidd: {{vote.img.focal}}</div>
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <div class="col-12">
                                                <h1 >{{vote.Position}}) {{vote.Title}}</h1>
                                            </div>
                                            <div class="col-12">
                                                {{vote.SumVote}} {{vote.highestVote}} <span *ngFor="let vc of vote.VoteCounts"> (Vote: {{vc.vote}}, Count: {{vc.count}}), </span>
                                            </div>
                                            <div class="row" *ngFor="let c of vote.Comments">
                                                <div class="col-12" style="border-width:1px;border-style:solid;" [innerHtml]="c.Comment">
                                                    
                                                </div>
                                                <div class="col-12">
                                                    <div style="font-style: italic; font-size: 80%;position:absolute;right:15px">/ Anonymt</div>
                                                    <div>&nbsp;</div>
                                                </div>
                                                <div class="col-12">
                                                <hr/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </div>
</div>