import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { CompetitionService } from '../competition/competition.service';
import { IInvitation } from '../model/IInvitation';
import { Icompetition } from '../model/Icompetition';
import { AuthService } from '../authentication/auth-service.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '../model/user';
import { LoginRequest } from '../model/login-request';
import { ToastService } from '../service/toast.service';

const phoneEmailRegex = /^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/;

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss']
})
export class InvitationComponent implements OnInit {
  invitation: IInvitation | null = null;
  valid = false;
  invitationAccepted = false;
  competition: Icompetition | null | undefined = null;

  email: string = '';
  name: string = '';
  pwd: string = '';
  pwd2: string = '';

  emailError: string | null = null;
  signupError: string | null = null;
  loggedIn: boolean = false;

  constructor(private route: ActivatedRoute, private competitionService: CompetitionService, protected authService: AuthService, private router: Router, private toaster: ToastService) {
  }

  ngOnInit(): void {
    this.authService.loggedIn.subscribe(l => this.loggedIn = l);
    this.competitionService.fetchInvitationHash(this.route.snapshot.paramMap.get('hash')).subscribe((data: IInvitation[]) => {
      if (data && data.length === 1) {
        this.invitation = data[0];

        this.competitionService.fetchCompetitions().subscribe((cdata: Icompetition[]) => {
          this.competition = cdata.find((f: Icompetition) => f.Id === this.invitation ?.Competition);
        })
      }
    });

  }

  onEmail() {
    const r = new RegExp(phoneEmailRegex);
    this.emailError = r.test(this.email) ? null : 'Ogiltig epostadress';

    if (!this.emailError) {
      this.onChange();
    }
  }

  onChange() {
    const r = new RegExp(phoneEmailRegex);
    this.valid = r.test(this.email) && !!this.name && this.name.length > 1 && !!this.pwd && this.pwd.length > 2 && this.pwd === this.pwd2;
  }

  onRegister() {
    const u: User = {
      email: this.email,
      password: this.pwd,
      name: this.name
    }
    this.authService.signup(u).subscribe(data => {
      this.signupError = null;
    },
      error => {
        console.log(error);
        this.signupError = error.error;
      });
  }

  onLogin() {
    const loginRequest: LoginRequest = new LoginRequest();
    loginRequest.email = this.email;
    loginRequest.password = this.pwd;
    this.authService.login(loginRequest).subscribe((response) => {
      //      this.loggedIn = !!response.confirmed;
      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
      if (response) {
        this.toaster.show("success", "Inloggning", "Inloggningen lyckades.");
      }
      else {
        this.toaster.show("error", "Inloggning", "Ogiltigt epost eller lösenord.");
      }
    });
  }

  recover() {
    this.authService
      .sendRecover(this.email ? this.email : '')
      .subscribe((response) => {
        this.toaster.show("success", "Lösenord", "En epost med länk till återställande av lösenordet är skickat till dig.", 20000);
        //this.router.navigate(['recovery']);
      });
  }

  acceptInvitation() {
    this.competitionService.acceptInvitation(this.route.snapshot.paramMap.get('hash')).subscribe(data => {
      this.invitationAccepted = true;
    },
      error => {
        console.log(error);
        this.signupError = error.error;
      });
  }

  validatePassword() {
    const p = this.pwd;
    /*errors = [];
    if (p.length < 8) {
        errors.push("Your password must be at least 8 characters");
    }
    if (p.search(/[a-z]/i) < 0) {
        errors.push("Your password must contain at least one letter."); 
    }
    if (p.search(/[0-9]/) < 0) {
        errors.push("Your password must contain at least one digit.");
    }
    if (errors.length > 0) {
        alert(errors.join("\n"));
        return false;
    }
    return true;*/
  }
}
