<nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="container">
        <a class="navbar-brand" href="#"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse"
            aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" routerLink="/home" routerLinkActive="active-link" data-toggle="collapse" data-target=".navbar-collapse.show">Hem</a
          >
        </li>
        <li class="nav-item dropdown" *ngIf="loggedIn">
          <a
            href="#"
            class="nav-link dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span class="nav-label">Tävling</span><span class="caret"></span
          ></a>
                    <ul class="dropdown-menu">
                        <a class="nav-link" routerLink="/competition" routerLinkActive="active-link" data-toggle="collapse" data-target=".navbar-collapse.show">Tävling</a
            >
            <a
              class="nav-link"
              routerLink="/addcompetitionimages"
              routerLinkActive="active-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              >Lägg till bilder</a
            >
            <a
              class="nav-link"
              routerLink="/votecompetition"
              routerLinkActive="active-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              >Rösta</a
            >
            <a
              class="nav-link"
              routerLink="/competitionresult"
              routerLinkActive="active-link"
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              >Resultat</a
            >
          </ul>
        </li>
        <li *ngIf="loggedIn" class="nav-item">
            <a class="nav-link" routerLink="/users" routerLinkActive="active-link" data-toggle="collapse" data-target=".navbar-collapse.show">Användare</a>
                </li>
                </ul>
                <ul class="nav navbar-nav flex-row justify-content-between ml-auto">
                    <li class="nav-item order-2 order-md-1">
                        <a href="#" class="nav-link" title="settings"><i class="fa fa-cog fa-fw fa-lg"></i></a>
                    </li>
                    <li class="dropdown order-1" *ngIf="!loggedIn">
                        <button type="button" id="dropdownMenu1" data-toggle="dropdown" class="btn btn-outline-loggin dropdown-toggle" data-target="#logindrop">
                            Logga in <span class="caret"></span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-right mt-2" id="logindrop" style="width:400px">
                            <li class="px-3 py-2">
                                <form class="form" role="form">
                                    <div class="form-group">
                                        <div>Epost</div>
                                        <input id="emailInput" name="email" [(ngModel)]="email" placeholder="Skriv in din epostadress" class="form-control form-control-sm" type="text"
                                            required="" />
                                    </div>
                                    <div class="form-group">
                                        <div>Lösenord</div>
                                        <input id="passwordInput" type="password" name="password" [(ngModel)]="password" placeholder="Skriv in ditt lösenord" class="form-control form-control-sm"
                                            required="" />
                                    </div>
                                    <div class="form-group">
                                        <button (click)="login()" class="btn btn-primary btn-block" [disabled]="!email || !password" data-toggle="collapse" data-target=".navbar-collapse.show">
                                            Logga in
                                        </button>
                                    </div>
                                    <div class="form-group text-center">
                                        <button (click)="recover()" class="btn btn-primary btn-block" [disabled]="!email">
                                            Glömt lösenord
                                        </button>
                                    </div>
                                </form>
                            </li>
                        </ul>
                    </li>
                    <li class="dropdown order-1" *ngIf="loggedIn">
                        <button type="button" id="dropdownMenu1" data-toggle="dropdown" class="btn btn-outline-loggin dropdown-toggle">
            Logga ut<span class="caret"></span>
          </button>
                        <ul class="dropdown-menu dropdown-menu-right mt-2">
                            <li class="px-3 py-2">
                                <form class="form" role="form">
                                    <div>{{user}}</div>
                                    <div class="form-group">
                                        <button class="btn btn-primary btn-block" (click)="logout()">
                    Logga ut
                  </button>
                                    </div>
                                </form>
                            </li>
                        </ul>
                    </li>
                    <li class="nav-item dropdown">
                        <a
                        href="#"
                        class="nav-link dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        >
                        <span class="nav-label">Hjälp</span><span class="caret"></span></a>
                        <ul class="dropdown-menu">
                            <a class="nav-link" routerLink="/help/register" routerLinkActive="active-link" data-toggle="collapse" data-target=".navbar-collapse.show">Registrera dig</a>
                            <a
                                class="nav-link"
                                routerLink="//help/addimage"
                                routerLinkActive="active-link"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show">
                                Lägg till bilder
                            </a>
                            <a
                                class="nav-link"
                                routerLink="/help/vote"
                                routerLinkActive="active-link"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                                >Rösta
                            </a>
                            <a
                                class="nav-link"
                                routerLink="/competitionresult"
                                routerLinkActive="active-link"
                                data-toggle="collapse"
                                data-target=".navbar-collapse.show"
                                >
                                Resultat
                            </a>
                        </ul>
                    </li>
                </ul>
        </div>
    </div>
</nav>