import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IInvitation } from '../../model/IInvitation';

@Component({
  selector: 'app-invitation-modal',
  templateUrl: './invitation-modal.component.html',
  styleUrls: ['./invitation-modal.component.scss']
})
export class InvitationModalComponent implements OnInit {
  invitation: IInvitation = { Hash: '', Competition: 0, ValidTo: '', CanVote: 0, CanParticipate: 0, tag: '' };
  public validToSend = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  passBack() {
    this.activeModal.close(this.invitation);
  }
}
