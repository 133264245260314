import { Component, OnInit, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  file: File | undefined;
  title: string = '';
  comment: string = '';
  url: string = '';
  validToSend: boolean = true;
  fileUpload: any;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.fileUpload = document.getElementById('fileUpload');
  }

  dropClick() {
  }

  onDropFiles(files: any): void {
    const filess = [...files];
    console.log(filess);
  }

  @HostListener('drop', ['$event']) public drop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    //this.backgroundColor = DropColor.Default;

    let fileList = event ?.dataTransfer ?.files;
    let files: any[] = [];
    if (fileList) {
      for (let i = 0; i < fileList.length; i++) {
        const file = fileList[i];
        const url = window.URL.createObjectURL(file);
        files.push({ file, url });
      }
      if (files.length > 0) {
        this.file = files[0].file;
        if (this.file) {
          this.title = this.file.name;
          var oFReader = new FileReader();
          oFReader.readAsDataURL(this.file);

          oFReader.onload = function (oFREvent) {
            if (oFREvent.target == null) return;
            if (oFREvent.target.result == null) return;
            document.getElementById('uploadPreview') ?.setAttribute('src', oFREvent.target.result as string);
          };
        }
        //  this.dropFiles.emit(files);
      }
    }
  }

  onDragOver(event: any) {
    event.stopPropagation();
    event.preventDefault();
    //this.active = true;
  }

  @HostListener('dragover', ['$event']) public dragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    //this.backgroundColor = DropColor.Over;
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    if (this.file) {
      this.title = this.file.name;
      var oFReader = new FileReader();
      oFReader.readAsDataURL(this.file);

      oFReader.onload = function (oFREvent) {
        if (oFREvent.target == null) return;
        if (oFREvent.target.result == null) return;
        document.getElementById('uploadPreview') ?.setAttribute('src', oFREvent.target.result as string);
      };
    }
  }

  passBack() {
    this.activeModal.close({ file: this.file, title: this.title, comment: this.comment });//this.invitation);
  }
}
