import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { config } from '../config/config';
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { IInvitation } from '../model/IInvitation';

@Injectable({
  providedIn: 'root'
})
export class CompetitionService {

  constructor(private http: HttpClient) { }

  fetchCompetitions() {
    return this.http.get<any>(`${config['apiUrl']}/competition/fetchCompetition`);
  }

  fetchSubCompetitions(Id: number) {
    return this.http.get<any>(`${config['apiUrl']}/competition/fetchSubCompetition/` + Id, );
  }

  addCompetition(competition: Icompetition) {
    return this.http
      .post<Icompetition>(
      `${config['apiUrl']}/competition/addCompetition`,
      competition
      );

  }

  deleteCompetition(competition: Icompetition) {
    return this.http
      .post<Icompetition>(
      `${config['apiUrl']}/competition/deleteRestCompetition`,
      competition
      );

  }

  updateCompetition(competition: Icompetition) {
    return this.http
      .post<Icompetition>(`${config['apiUrl']}/competition/updateCompetition`,
      competition
      );
  }

  createInvitation(invitation: IInvitation) {
    return this.http
      .post<IInvitation>(
      `${config['apiUrl']}/competition/createInvitation`,
      invitation
      );

  }

  fetchInvitations(Id: number) {
    return this.http.get<IInvitation[]>(`${config['apiUrl']}/competition/fetchInvitations/` + Id);
  };

  fetchInvitationHash(Hash: string | null) {
    return this.http.get<IInvitation[]>(`${config['apiUrl']}/competition/fetchInvitationHash/` + Hash);
  };

  acceptInvitation(Hash: string | null) {
    return this.http.post<IInvitation[]>(`${config['apiUrl']}/competition/acceptInvitation/`, { hash: Hash });
  };

  addSubCompetition(competition: Isubcompetition) {
    return this.http
      .post<Isubcompetition>(
      `${config['apiUrl']}/competition/addSubCompetition`,
      competition
      );

  }

  updateSubCompetition(competition: Isubcompetition) {
    return this.http
      .post<Isubcompetition>(`${config['apiUrl']}/competition/updateSubCompetition`,
      competition
      );
  }

}
