import { Observable, of } from "rxjs";
import { Token } from "../model/token";
import { User } from "../model/user";
import { AuthStrategy } from "./auth-strategy";

export class JwtAuthStrategy implements AuthStrategy<Token> {

  private readonly JWT_TOKEN = 'JWT_TOKEN';

  doLoginUser(token: any): void {
    localStorage.setItem(this.JWT_TOKEN, token);
  }

  doLogoutUser(): void {
    localStorage.removeItem(this.JWT_TOKEN);
  }

  getCurrentUser(): Observable<User> {
    const token = this.getToken();
    if (token) {
      const encodedPayload = token.split('.')[1];
      const payload = window.atob(encodedPayload);
      return of(JSON.parse(payload));
    } else {
      return of(undefined) as any;
    }
  }

  getToken() {
    const item = localStorage.getItem(this.JWT_TOKEN);
    if (item === 'undefined')
      return undefined;
    return item;
  }
}