<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row" style="margin-bottom:15px;">
        <label class="col-12">{{modalText}}</label>
    </div>
</div>
<div class="modal-footer">
    <button (click)="passYes()" type="submit" class="btn btn-primary">
    Ja
  </button>
    <button (click)="passNo()" type="submit" class="btn btn-primary">
    Nej
  </button>
</div>