<div class="modal-body fullscreen">
    <div class="row">
        <div class="col-12">
  <button
    type="button"
    class="close black-modal-cross"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
        </div>
    </div>
  <ngb-carousel
    [interval]="500000"
    [animation]=false
    [wrap]=false
    [pauseOnHover]="true"
    [pauseOnFocus]="true"
    [activeId]="'3'"
    style="
      border-color: #00000057;
      border-style: solid;
      border-width: 4px;
      max-height: 95vh;
     background-color: #212124;
     "
  >
    <ng-template
      ngbSlide
      *ngFor="let slide of images; index as idx"
      id="lightbox-{{ idx }}"
    >
      <img
        src="https://foto.erikslada.se/uploads{{ slide.img.imgUrl }}"
        class="d-block"
        style="
          max-width: 100%;
          max-height: 90vh;
          margin-left: auto;
          margin-right: auto;
        "
        redZoom="https://foto.erikslada.se/uploads{{ slide.img.imgUrl }}"
        redZoomClass="red-zoom--style--overlay"
        redZoomBehavior="hover"
          />
      <div class="carousel-caption">
          <div class="row">
                <div class="col-12">
                    {{slide.img.Title}}
                </div>
            </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
