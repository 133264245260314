import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { IVoteContainer } from '../model/IVoteContainer';
import { ICompetitionImageVote } from '../model/ICompetitionImageVote';

@Component({
  selector: 'app-slider-modal',
  templateUrl: './slider-modal.component.html',
  styleUrls: ['./slider-modal.component.scss']
})
export class SliderModalComponent implements OnInit {
  @Input() public images: IVoteContainer[] = [];
  @ViewChild(NgbCarousel, { static: true }) carousel?: NgbCarousel;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
