<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Tävling</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <label class="col-3">Namn</label>
        <div class="col-6">
            <input [(ngModel)]="competition.CompetitionName">
        </div>
    </div>
    <div class="row">
        <div class="col-12"><strong>Sista datum för:</strong></div>
    </div>
    <div class="row">
        <label class="col-3">Bilduppladdning</label>
        <div class="col-6">
            <input type="date" [(ngModel)]="competition.EndoParticipation">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Röstning</label>
        <div class="col-6">
            <input type="date" [(ngModel)]="competition.EndOfVoting">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Deltagare kan lägga till bild</label>
        <div class="col-6">
            <input type="checkbox" [(ngModel)]="competition.participantCanAddImage">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Antal röster</label>
        <div class="col-6">
            <input type="number" [(ngModel)]="competition.numberOfVotes">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Antal bilder som får placering</label>
        <div class="col-6">
            <input type="number" [(ngModel)]="competition.numberOfPositionedImages">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Röstande kan se resultatet från och med</label>
        <div class="col-6">
            <input type="date" [(ngModel)]="competition.VisibleToVoterFrom">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Resultatet blir publikt</label>
        <div class="col-6">
            <input type="checkbox" [(ngModel)]="competition.PubliclyVisible">
        </div>
    </div>
    <div class="row" *ngIf="competition.PubliclyVisible">
        <label class="col-3">Resultatet blir publikt från och med</label>
        <div class="col-6">
            <input type="date" [(ngModel)]="competition.PubliclyVisibleFrom">
        </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="passBack()" type="submit" class="btn btn-primary" [disabled]="!validToSend">
    Ok
  </button>
</div>