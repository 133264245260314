<div class="row" *ngFor="let vote of images" style="padding-bottom:15px;">
    <div class="col-4">
        <div class="row">
            <div class="col-12">
                <div class="frame">
                    <div class="mat">
                        <img src="https://foto.erikslada.se/uploads/{{vote.img.imgUrl}}" 
                         (click)="showSlider(vote)" 
                        redZoom="https://foto.erikslada.se/uploads/{{vote.img.imgUrl}}"
                        redZoomClass="red-zoom--style--window"
                        redZoomBehavior="hover"
                        width="100%"
                    >
                    </div>
                </div>
            </div>
            <div class="col-12" *ngIf="vote.img.Camera && extraVisible">{{vote.img.Camera}}</div>
            <div class="col-12" *ngIf="vote.img.Model && extraVisible">{{vote.img.Model}}</div>
            <div class="col-12" *ngIf="vote.img.iso && extraVisible">ISO: {{vote.img.iso}}</div>
            <div class="col-12" *ngIf="vote.img.aperture && extraVisible">Bländare: {{vote.img.aperture}}</div>
            <div class="col-12" *ngIf="vote.img.shutter && extraVisible">Slutare: {{vote.img.shutter}}</div>
            <div class="col-12" *ngIf="vote.img.focal && extraVisible">Brännvidd: {{vote.img.focal}} mm</div>
        </div>
    </div>
    <div class="col-8">
        <div class="col-12">
            <h1 ><span *ngIf="vote.Position > 10">*</span><span *ngIf="vote.Position <= 10">{{vote.Position}}</span>) {{vote.Title}}</h1>
        </div>
        <div class="row" *ngIf="vote.img.Comment">
            <div class="col-12" style="border-width:1px;border-style:solid;border-color:green;" [innerHtml]="vote.img.Comment"></div>
        </div>
        
        <!--div class="col-12">
            {{vote.SumVote}} {{vote.highestVote}} <span *ngFor="let vc of vote.VoteCounts"> (Vote: {{vc.vote}}, Count: {{vc.count}}), </span>
        </div-->
        <div class="row" *ngFor="let c of vote.Comments">
            <div class="col-12" style="border-width:1px;border-style:solid;" [innerHtml]="c.Comment"></div>
            <div class="col-12">
                <div style="font-style: italic; font-size: 80%;position:absolute;right:15px">/ Anonymt {{c.UserId}}</div>
                <div>&nbsp;</div>
            </div>
            <div class="col-12">
                <hr/>
            </div>
        </div>
    </div>
</div>
