<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Tävling</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-6">
            <input type="file" id="fileUpload" style="display:none;" class="file-input" (change)="onFileSelected($event)" accept="image/jpeg" #fileUpload>
        </div>
    </div>
    <div class="row">
      <div class="drop-box" corpImgUpload (dropFiles)="onDropFiles($event)" (dragOver)="onDragOver($event)" (click)="fileUpload.click()" style="display: flex; justify-content: center; align-items: center; position:relative;">
        <img [src]="url" id="uploadPreview" style="width:100%; position:absolute;" z-inxex="2000"/>
        <span class="message" >Släpp en fil här eller klicka</span>
      </div>
    </div>
    <div class="row">
        <label class="col-3">Titel</label><div class="col-10"><input type="text" [(ngModel)]="title" style="width:100%"></div>
    </div>
    <div class="row" style="margin-bottom:15px;">
        <label class="col-4">Kommentar</label>
        <editor style="height: 100%;" apiKey="2u77d2vm9uzio1ru4aoi5qq0g0ingf2cdlphwbzdi7bag0u0" [(ngModel)]="comment" [init]="{
            formats : {
                underline : {inline : 'u'}
            },
            plugins: ['lists', 'link', 'paste', 'help', 'wordcount'],
            placeholder: 'Skriv din kommentar här...',
            menubar: '',
            promotion:false,
            height: '50vh',
            toolbar:
            'undo redo | bold italic underline | bullist numlist'
        }" class="col-12"></editor>
        <!--textarea [(ngModel)]="formattedText" class="col-12" style="height:50vh;"></textarea-->
    </div>
</div>
<div class="modal-footer">
    <button (click)="passBack()" type="submit" class="btn btn-primary" [disabled]="!validToSend">
    Ok
  </button>
</div>