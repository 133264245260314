import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from "rxjs/operators";
import { config } from '../config/config';
import { Icompetition } from '../model/Icompetition';
import { Isubcompetition } from '../model/Isubcompetition';
import { ICompetitionImage } from '../model/ICompetitionImage';
import { ICompetitionImageComment } from '../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../model/ICompetitionImageVote';

@Injectable({
  providedIn: 'root'
})
export class CompetitionPresentationService {

  constructor(private http: HttpClient) { }

  fetchCompetitions() {
    return this.http.get<any>(`${config['apiUrl']}/competition/fetchCompetitionResult`);
  }

  fetchSubCompetitions(Id: number) {
    return this.http.get<any>(`${config['apiUrl']}/competition/fetchSubCompetition/` + Id, );
  }

  fetchCompetitionImages(sub: Isubcompetition) {
    return this.http.get<ICompetitionImage[]>(`${config['apiUrl']}/competition/fetchCompetitionImages/` + sub.CompetitionId + '/' + sub.Id);
  }

  fetchCompetitionImageComments(CompetitionId: number, SubId: number, ImageId: number) {
    return this.http.post<ICompetitionImageComment[]>(`${config['apiUrl']}/competition/fetchCompetitionImageComments`, { CompetitionId: CompetitionId, SubId: SubId, ImageId: ImageId });
  }

  fetchCompetitionImageVotes(CompetitionId: number, SubId: number) {
    return this.http.post<ICompetitionImageVote[]>(`${config['apiUrl']}/competition/fetchCompetitionImageVotes`, { CompetitionId: CompetitionId, SubId: SubId });
  }

}
