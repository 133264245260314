import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Isubcompetition } from '../../model/Isubcompetition';
import { ICompetitionImage } from '../../model/ICompetitionImage';
import { ICompetitionImageComment } from '../../model/ICompetitionImageComment';
import { ICompetitionImageVote } from '../../model/ICompetitionImageVote';
import { IVoteContainer } from '../../model/IVoteContainer';
import { UserServiceService } from '../../users/user-service.service';
import { ResultModalComponent } from '../result-modal/result-modal.component';
import { SliderModalComponent } from '../../slider-modal/slider-modal.component';
import { Iuser } from '../../model/Iuser'
import { IComp, ISub, IVote, IVoter } from '../competition-result-types';

@Component({
  selector: 'app-images-result',
  templateUrl: './images-result.component.html',
  styleUrls: ['./images-result.component.scss']
})
export class ImagesResultComponent implements OnInit {
  @Input() competition: IComp | null = null;
  @Input() user: Iuser | undefined = undefined;
  @Input() images: IVote[] = [];
  extraVisible: boolean = false;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    const date1 = new Date();
    const date2 = new Date(this.competition ? this.competition.comp.VisibleToVoterFrom : '');
    this.extraVisible = this.user ?.globaladmin || this.competition ? date2 < date1 : false;
  }

  showSlider(img: IVote) {
    const modalRef = this.modalService.open(SliderModalComponent, {
      backdrop: 'static',
      size: 'xl',
    });
    modalRef.componentInstance.images = [img];
    /*setTimeout(() => {
      const focus = modalRef.componentInstance.carousel.pauseOnFocus;
      modalRef.componentInstance.carousel.pauseOnFocus = false;
      modalRef.componentInstance.carousel.select('lightbox-' + v.vote ?.SortOrder.toString());
      modalRef.componentInstance.carousel.pauseOnFocus = focus;
    }, 10);*/
    modalRef.result.then((result: any) => {
    });
  }

}
