<div class="container">
    <div class="card" style="margin-bottom: 15px">
        <div class="card-header">
            <div class="row" style="margin-left: '15px';margin-right:'15px'">
                <div *ngIf="invitation === null">Denna inbjudan är inte längre giltig</div>
                <div *ngIf="invitation !== null">Du är inbjuden till fototävling<span *ngIf="!!competition">en <strong>{{competition.CompetitionName}}</strong></span> som:
                    <ul>
                        <li *ngIf="invitation?.CanVote">röstare</li>
                        <li *ngIf="invitation?.CanParticipate">deltagare</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="card-body" *ngIf="invitation !== null">
            <div class="row" *ngIf="invitationAccepted">
                <div class="col-12">
                    Du har accepterat inbjudan
                </div>
            </div>
            <div class="row form-group" *ngIf="!loggedIn && !invitationAccepted">
                <div class="col-12">
                  <p>Du är inte inloggad.</p>
                  <p>Om du har registrerat dig tidigare för att rösta eller lägga till bilder, byter du till fliken "Registrerad sedan tidigare".</p>
                  <p>Skulle du inte vara registrerad sedan tidigare Fyller du i epost, namn och lösenord här under och trycker på Registrera dig</p>
                  <p>&nbsp;</p>
                </div>
                <div class="col-3"><label>Epost</label></div><div class="col-8"><input placeholder="Din epostadress" type="email" class="form-control" [(ngModel)]="email" (blur)="onEmail()"></div>
                <div class="small col-12" style="color:red;" *ngIf="emailError">{{emailError}}</div>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <tabset class='col-12'>
                    <tab heading="Inte tidigare registrerad">
                        <div class="col-3"><label>Namn</label></div><div class="col-8"><input type="text" class="form-control" [(ngModel)]="name" (blur)="onChange()"></div>
                        <div class="col-3"><label>Lösenord</label></div><div class="col-8"><input type="password" class="form-control" [(ngModel)]="pwd" (blur)="onChange()"></div>
                        <div class="col-3"><label>Upprepa Lösenord</label></div><div class="col-8"><input type="password" class="form-control" [(ngModel)]="pwd2" (blur)="onChange()"></div>
                        <div class="alert alert-danger col-12" role="alert" *ngIf="signupError">
                            {{signupError}}
                        </div>
                        <p>&nbsp;</p>
                        <div class="col-3"><Button class="btn btn-success" [disabled]="!valid" (click)="onRegister()">Registrera dig</Button></div>
                    </tab>
                    <tab heading="Registrerad sedan tidigare">
                        <div class="col-12">
                            <p>Skulle du ha glömt ditt lösenord skriver du in epostadressen där upp och trycker på "Glömt lösenord". Du bör då få en epost med länk för att kunna ändra lösenordet.</p> 
                        </div>
                        <div class="col-3"><label>Lösenord</label></div><div class="col-8"><input type="password" class="form-control" [(ngModel)]="pwd" (blur)="onChange()"></div>
                        <p>&nbsp;</p>
                        <div class="row">
                        <div class="col-12"><Button class="btn btn-success"  (click)="onLogin()" style="margin-bottom:1em;width:100%">Logga in</Button></div>
                        <div class="col-12"><Button class="btn btn-primary"  (click)="recover()" style="margin-bottom:1em;width:100%">Glömt lösenord</Button></div>
                        </div>
                    </tab>
                </tabset>
            </div>
            <div class="row" *ngIf="loggedIn && !invitationAccepted">
                <div class="col-3"><Button class="btn btn-primary" (click)="acceptInvitation()">Acceptera inbjudan</Button></div>
            </div>
            <div class="row" *ngIf="loggedIn && invitationAccepted">
                <div class="col-3">Du är nu registrerad</div>
            </div>
        </div>
    </div>
</div>
