<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Bildkommentar</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="card">
        <div class=card-header>
            <div class="row">
                <div class="col-12">
                    <p>Titta på och bedöm hur fotografen har utnyttjat tekniken och eventuella speciella effekter för att nå sitt mål. Titta även på bildutsnitt (beskärning), bildvinkel, ljussättning, färg, skärpa och blickriktning. Det är också viktigt att bedöma om fotografiet framkallar en känsla eller berättar en historia.</p>
                    <p>Tänk på att skriva kommentaren i en positiv anda. Skriv inte saker om hur dålig bilden är. Skriv bara vad som var bra med bilden och ge gärna tips på vad du anser som skulle kunnat göra bilden ännu bättre, som annan skärpa och beskärning. Om du vill och kan får du även kommentera bildbehandlingen, men ge i så fall en kort beskrivning på vad du hade önskat, exempelvis mer kontrast eller annorlunda färgmättnad.</p>
                    <p>Försök att vara kortfattad, men skriv ändå mer än ett västerbottniskt "schuu", sagt på inandning ...</p>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row" style="margin-bottom:15px;">
                <label class="col-4">Kommentar</label>
                <editor style="height: 100%;" apiKey="2u77d2vm9uzio1ru4aoi5qq0g0ingf2cdlphwbzdi7bag0u0" [(ngModel)]="formattedText" [init]="{
                    formats : {
                        underline : {inline : 'u'}
                    },
                    plugins: ['lists', 'link', 'paste', 'help', 'wordcount'],
                    placeholder: 'Skriv din kommentar här...',
                    menubar: '',
                    promotion:false,
                    height: '50vh',
                    toolbar:
                    'undo redo | bold italic underline | bullist numlist'
                }" class="col-12"></editor>
                <!--textarea [(ngModel)]="formattedText" class="col-12" style="height:50vh;"></textarea-->
            </div>
            <div class="row">
                <label class="col-3">Visa mitt namn under kommentaren</label><div class="col-9"><input type="checkbox" [(ngModel)]="IsAnonymous"></div>
            </div>
        </div>
    </div>
  <div class="modal-footer">
    <button (click)="passBack()" type="submit" class="btn btn-primary" [disabled]="!validToSend">
    Spara
  </button>
  </div>
</div>