<div class="modal-body fullscreen">
    <div class="row">
        <div class="col-12">
            <div style="
                position: absolute;
                top: 0px;
               left: 15px;"
            >
                <button
                type="button"
                style="
                color:blue;
                z-index: 2000;
                "
                [attr.disabled]="disabled ? true: null"
                (click)="showResult()"
                >
                    <span><fa-icon [icon]="['fas', 'play']"></fa-icon></span>
                </button>
                <button
                type="button"
                style="
                color:blue;
                z-index: 2000;
                "
                [attr.disabled]="disabled ? true: null"
                (click)="showResultQuick()"
                >
                    <span><fa-icon [icon]="['fas', 'fast-forward']"></fa-icon></span>
                </button>
            <input id="ex1" data-slider-id='ex1Slider' type="text" data-slider-min="0" data-slider-max="100" data-slider-step="1" data-slider-value="25"/>
            <span style="color:white">{{volume}}</span>
            </div>
            <button
            type="button"
            class="close black-modal-cross"
            aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"
            >
                <span aria-hidden="true">&times;</span>
            </button>
            
        </div>
    </div>
    <canvas #mainCanvas id="mainCanvas" width="95%" height="05vh"  class="border" style="max-height:calc(100vh - 57px)">
        HTML 5 canvas stöds inte
    </canvas>
</div>
