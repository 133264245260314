<div class="container">
    <div class="card" style="margin-bottom: 15px">
        <div class="card-header">
            <div class="row" style="margin-left: '15px';margin-right:'15px'">

                <div class="col-5"><strong>Tävlingsnamn</strong>
                </div>
                <div class="col-5"><strong>Sista dag för röstning</strong>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="card" *ngFor="let competition of competitions">
                <div class="card-header">
                    <div class="row">
                        <div class="col-5">{{competition.CompetitionName}}</div>
                        <div class="col-3">{{competition.EndOfVoting | date:'yyyy-MM-dd'}}</div>
                        <div class="col-4">
                            <strong>Du behöver placera {{competition.numberOfVotes}} bilder.</strong>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <tabset>
                        <tab *ngFor="let sub of competition.subCompetitions let i = index" heading="{{sub.SubName}}">
                            <div class="card" style="margin-bottom: 15px">
                                <div class="card-header">
                                    <div class="row" style="margin-left: '15px';margin-right:'15px'">
                                        <div class="col-9" [innerHtml]="sub.SubCompTheme"></div>
                                        <div class="col-3">                                                            
                                            <Button
                                                (click)="onVoteClick(competition ,sub)" class="btn btn-primary position-relative"
                                                ngbPopover="Skicka in röstning"
                                                triggers="mouseenter:mouseleave"
                                                popoverTitle="Rösta"
                                            >
                                                <fa-icon [icon]="['fas', 'envelope']"></fa-icon> Spara röstning
                                            </Button>
                                        </div>

                                    </div>
                                </div>
                                
                                <div class="card-body" (mousedown)="onMouseDown($event)" (mouseup)="onMouseUp($event)" (mouseleave)="onMouseLeave($event)" (mousemove)="onMouseMove($event)">
                                    <div class="row">
                                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" *ngFor="let vote of sub.votes">
                                            <div class="card">
                                                <div class="card-body">
                                                    <img src="https://foto.erikslada.se/uploads/{{vote.img.imgUrl}}" srcset="{{vote.img.srcSet}}" width="100%" (click)="showSlider(sub, vote)" [ngxDraggable]="true" [ngxDragData]="vote" [ngxDragTag]="competition.IdString??'dummy'" [ngxDropTags]="[competition.IdString??'dummy']" (ngxDroppable)="onDrop($event, vote)">
                                                </div>
                                                <div class="card-footer">
                                                    <div class="row">
                                                        <div class="col-12" style="text-align: center;"><strong>{{vote.img.VoteTitle}}</strong></div>
                                                    </div>
                                                    <!--div class="row" *ngIf="vote.img.iso">
                                                        <div class="col-12 col-lg-6">ISO</div>
                                                        <div class="col-12 col-lg-6">{{vote.img.iso}}</div>
                                                    </div>
                                                    <div class="row" *ngIf="vote.img.shutter">
                                                        <div class="col-12 col-lg-6">Slutare</div>
                                                        <div class="col-12 col-lg-6">{{vote.img.shutter}}</div>
                                                    </div>
                                                    <div class="row" *ngIf="vote.img.aperture">
                                                        <div class="col-12 col-lg-6">Bländare</div>
                                                        <div class="col-12 col-lg-6">{{vote.img.aperture}}</div>
                                                    </div>
                                                    <div class="row" *ngIf="vote.img.focal">
                                                        <div class="col-12 col-lg-6">Brännvidd</div>
                                                        <div class="col-12 col-lg-6">{{vote.img.focal}}</div>
                                                    </div-->
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <Button 
                                                                (click)="onAddClick(vote)" 
                                                                class="btn btn-primary position-relative"
                                                                ngbPopover="Skriv eller ändra en kommentar"
                                                                triggers="mouseenter:mouseleave"
                                                                popoverTitle="Kommentar"
                                                            >
                                                                <fa-icon [icon]="['fas', 'pen']"></fa-icon>
                                                                <span *ngIf="vote.comment" class="position-absolute top-0 start-100 translate-middle p-2 bg-info border border-light rounded-circle"></span>
                                                            </Button>
                                                            <span>&nbsp;</span>
                                                            <Button 
                                                                (click)="onZoomClick(sub, vote)" class="btn btn-primary position-relative"
                                                                ngbPopover="Jämför bilder intill varandra"
                                                                triggers="mouseenter:mouseleave"
                                                                popoverTitle="Jämförelse"
                                                            >
                                                                <fa-icon [icon]="['fas', 'cog']"></fa-icon>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </tab>
                    </tabset>
                </div>
            </div>
        </div>
    </div>
</div>
