<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Rösta</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row" style="margin-bottom:15px"><div class="col-12" style="border-bottom-style: solid;">Kontrollera poängen och registrera<button (click)="passBack()" type="submit" class="btn btn-primary" style="float:right" [disabled]="!validToSend">
    Registrera röstningen
  </button>
</div></div>
    <div class="row" style="margin-top:5px;margin-bottom:5px;">
        <div class="col-1">
        </div>
        <div class="col-5">
            <strong>Namn</strong>
        </div>
        <div class="col-1">
            <strong>Poäng</strong>
        </div> 
    </div>
    <div class="row" *ngFor="let image of images let i = index; let odd = odd" style="margin-top:5px;margin-bottom:5px;"  [ngClass]="{'odd': odd}">
        <div class="col-1">
            <img
                src="https://foto.erikslada.se/uploads{{ image.img.imgUrl }}"
                srcset="{{image.img.srcSet}}"
                style="
                    max-width: 100%;
                    max-height: 90vh;
                    margin-left: auto;
                    margin-right: auto;
                "
            />
        </div>
        <div class="col-5">
            {{image.img.Title}}
        </div>
        <div class="col-1" *ngIf="image.vote">

            <input type="number" [(ngModel)]="image.vote.VoteValue" disabled>
            </div>
    </div>
</div>
<div class="modal-footer">
    <button (click)="passBack()" type="submit" class="btn btn-primary" [disabled]="!validToSend">
    Registrera röstningen
  </button>
</div>