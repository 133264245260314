import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { IVoteContainer } from '../../model/IVoteContainer';
import { ICompetitionImageVote } from '../../model/ICompetitionImageVote';

@Component({
  selector: 'app-entervote-modal',
  templateUrl: './entervote-modal.component.html',
  styleUrls: ['./entervote-modal.component.scss']
})
export class EntervoteModalComponent implements OnInit {
  @Input() public images: IVoteContainer[] = [];
  public validToSend=true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  passBack() {
    this.activeModal.close(this.images);
  }
}
