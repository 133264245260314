import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Iuser } from '../../model/Iuser';

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  public user: Iuser = {
    username: '',
    usermail: '',
    iduser: 0,
    globaladmin: 0,
    password: '',
    reset: '',
    resetlimit: ''
  };
  public validToSend = true;

  constructor(
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  passBack() {
    this.activeModal.close(this.user);
  }
}
