<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Tävling</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
    <div class="row">
        <label class="col-3">Namn</label>
        <div class="col-6">
            <input [(ngModel)]="subcompetition.SubName">
        </div>
    </div>
    <div class="row">
        <label class="col-3">Beskrivning av temat</label>
                <editor style="height: 100%;" apiKey="2u77d2vm9uzio1ru4aoi5qq0g0ingf2cdlphwbzdi7bag0u0" [(ngModel)]="subcompetition.SubCompTheme" [init]="{
                    formats : {
                        underline : {inline : 'u'}
                    },
                    plugins: ['lists', 'link', 'paste', 'help', 'wordcount'],
                    placeholder: 'Skriv din kommentar här...',
                    menubar: '',
                    promotion:false,
                    height: '50vh',
                    toolbar:
                    'undo redo | bold italic underline | bullist numlist'
                }" class="col-12"></editor>
        <!--div class="col-6">
            <input [(ngModel)]="subcompetition.SubCompTheme">
        </div-->
    </div>
</div>
<div class="modal-footer">
    <button (click)="passBack()" type="submit" class="btn btn-primary" [disabled]="!validToSend">
    Ok
  </button>
</div>