import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Icompetition } from '../../model/Icompetition';

@Component({
  selector: 'app-competion-modal',
  templateUrl: './competion-modal.component.html',
  styleUrls: ['./competion-modal.component.scss']
})
export class CompetionModalComponent implements OnInit {
  public competition: Icompetition = {
    Id: 0,
    CompetitionName: '',
    EndOfVoting: '',
    EndoParticipation: '',
    participantCanAddImage: 1,
    numberOfVotes: 0,
    numberOfPositionedImages: 0,
    Logo: '',
    VisibleToVoterFrom: '',
    PubliclyVisible: 0,
    PubliclyVisibleFrom: null,
    subCompetitions: null,
    invitations: null
  };
  public validToSend = true;

  constructor(
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  passBack() {
    if (!this.competition.PubliclyVisible) {
      this.competition.PubliclyVisible = 0;
      this.competition.PubliclyVisibleFrom = null;
    } else {
      this.competition.PubliclyVisible = 1;
    }
    this.activeModal.close(this.competition);
  }
}
