import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-yes-no-modal',
  templateUrl: './yes-no-modal.component.html',
  styleUrls: ['./yes-no-modal.component.scss']
})
export class YesNoModalComponent implements OnInit {
  @Input() public title: string = 'Tuppp';
  @Input() public modalText: string = 'Topp'

  constructor(public activeModal: NgbActiveModal) {

  }

  ngOnInit(): void {
  }

  passYes() {
    this.activeModal.close(true);
  }

  passNo() {
    this.activeModal.close(false);
  }
}
